
import { virtualPageView } from '@/plugins/gtm-helper'

export default {
  components: {},
  layout: 'default',
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  head() {
    return {
      titleTemplate: '%s',
      title: this.isNotFound ? this.$t('not_found') : this.$t('server_error'),
    }
  },
  computed: {
    isNotFound() {
      return this.error.statusCode === 404
    },
    errorCode() {
      return this.error?.statusCode
    },
  },
  mounted() {
    setTimeout(() => {
      // GTM: virtualPageView
      virtualPageView(
        this.$route,
        'Other',
        this.isNotFound ? this.$t('not_found') : this.$t('server_error')
      )
    }, 250)
  },
}
