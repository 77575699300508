import Vue from 'vue'
import AppDialogMessage from '~/components/AppDialogMessage.vue'
import LoginModal from '~/containers/account/LoginModal.vue'
import SignupModal from '~/containers/account/SignupModal.vue'
import GuestModal from '~/containers/account/GuestModal.vue'
import ResetModal from '~/containers/account/ResetModal.vue'

export default (ctx, inject) => {
  inject('message', (props) => {
    return new Promise((resolve) => {
      const dialog = new Vue({
        router: ctx.app.router,
        methods: {
          closeHandler(val) {
            this.$destroy()
            this.$el.remove()
            return val
          },
        },
        render(h) {
          return h(AppDialogMessage, {
            props: {
              ...props,
            },
            on: {
              close: (val) => resolve(this.closeHandler(val)),
            },
          })
        },
      }).$mount()
      document.body.appendChild(dialog.$el)
    })
  })
  inject('userLogin', (props) => {
    return new Promise((resolve) => {
      const dialog = new Vue({
        router: ctx.app.router,
        store: ctx.store,
        ...ctx,
        methods: {
          closeHandler(val) {
            this.$destroy()
            this.$el.remove()
            return val
          },
        },
        render(h) {
          return h(LoginModal, {
            props: {
              ...props,
            },
            on: {
              close: (val) => resolve(this.closeHandler(val)),
            },
          })
        },
      }).$mount()
      document.body.appendChild(dialog.$el)
    })
  })
  inject('userSignup', (props) => {
    return new Promise((resolve) => {
      const dialog = new Vue({
        router: ctx.app.router,
        store: ctx.store,
        ...ctx,
        methods: {
          closeHandler(val) {
            this.$destroy()
            this.$el.remove()
            return val
          },
          backHandler() {
            this.closeHandler()
            this.$userLogin({ ...props, guest: true })
          },
        },
        render(h) {
          return h(SignupModal, {
            props: {
              ...props,
            },
            on: {
              close: (val) => resolve(this.closeHandler(val)),
              back: () => resolve(this.backHandler()),
            },
          })
        },
      }).$mount()
      document.body.appendChild(dialog.$el)
    })
  })
  inject('userGuest', (props) => {
    return new Promise((resolve) => {
      const dialog = new Vue({
        router: ctx.app.router,
        store: ctx.store,
        ...ctx,
        methods: {
          closeHandler(val) {
            this.$destroy()
            this.$el.remove()
            return val
          },
        },
        render(h) {
          return h(GuestModal, {
            props: {
              ...props,
            },
            on: {
              close: (val) => resolve(this.closeHandler(val)),
            },
          })
        },
      }).$mount()
      document.body.appendChild(dialog.$el)
    })
  })
  inject('userReset', (props) => {
    return new Promise((resolve) => {
      const dialog = new Vue({
        router: ctx.app.router,
        store: ctx.store,
        ...ctx,
        methods: {
          closeHandler(val) {
            this.$destroy()
            this.$el.remove()
            return val
          },
          backHandler() {
            this.closeHandler()
            this.$userLogin({ ...props })
          },
        },
        render(h) {
          return h(ResetModal, {
            props: {
              ...props,
            },
            on: {
              close: (val) => resolve(this.closeHandler(val)),
              back: () => resolve(this.backHandler()),
            },
          })
        },
      }).$mount()
      document.body.appendChild(dialog.$el)
    })
  })
}
