export const NewCustomerReviews = () => import('../../components/product/NewCustomerReviews.vue' /* webpackChunkName: "components/new-customer-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductScore = () => import('../../components/product/ProductScore.vue' /* webpackChunkName: "components/product-score" */).then(c => wrapFunctional(c.default || c))
export const ProductScoreMobile = () => import('../../components/product/ProductScoreMobile.vue' /* webpackChunkName: "components/product-score-mobile" */).then(c => wrapFunctional(c.default || c))
export const QuestionForm = () => import('../../components/product/QuestionForm.vue' /* webpackChunkName: "components/question-form" */).then(c => wrapFunctional(c.default || c))
export const AddressSummary = () => import('../../components/checkout/AddressSummary.vue' /* webpackChunkName: "components/address-summary" */).then(c => wrapFunctional(c.default || c))
export const CardTypes = () => import('../../components/checkout/CardTypes.vue' /* webpackChunkName: "components/card-types" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAdmin = () => import('../../components/checkout/CheckoutAdmin.vue' /* webpackChunkName: "components/checkout-admin" */).then(c => wrapFunctional(c.default || c))
export const CheckoutBag = () => import('../../components/checkout/CheckoutBag.vue' /* webpackChunkName: "components/checkout-bag" */).then(c => wrapFunctional(c.default || c))
export const CheckoutOrderSummary = () => import('../../components/checkout/CheckoutOrderSummary.vue' /* webpackChunkName: "components/checkout-order-summary" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPanel = () => import('../../components/checkout/CheckoutPanel.vue' /* webpackChunkName: "components/checkout-panel" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepButton = () => import('../../components/checkout/CheckoutStepButton.vue' /* webpackChunkName: "components/checkout-step-button" */).then(c => wrapFunctional(c.default || c))
export const CheckoutStepHeader = () => import('../../components/checkout/CheckoutStepHeader.vue' /* webpackChunkName: "components/checkout-step-header" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSubmitButton = () => import('../../components/checkout/CheckoutSubmitButton.vue' /* webpackChunkName: "components/checkout-submit-button" */).then(c => wrapFunctional(c.default || c))
export const DiscountCodeForm = () => import('../../components/checkout/DiscountCodeForm.vue' /* webpackChunkName: "components/discount-code-form" */).then(c => wrapFunctional(c.default || c))
export const ExpressPayment = () => import('../../components/checkout/ExpressPayment.vue' /* webpackChunkName: "components/express-payment" */).then(c => wrapFunctional(c.default || c))
export const PaymentCard = () => import('../../components/checkout/PaymentCard.vue' /* webpackChunkName: "components/payment-card" */).then(c => wrapFunctional(c.default || c))
export const PaymentCardField = () => import('../../components/checkout/PaymentCardField.vue' /* webpackChunkName: "components/payment-card-field" */).then(c => wrapFunctional(c.default || c))
export const PaymentFailedMessageBox = () => import('../../components/checkout/PaymentFailedMessageBox.vue' /* webpackChunkName: "components/payment-failed-message-box" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethodRadio = () => import('../../components/checkout/PaymentMethodRadio.vue' /* webpackChunkName: "components/payment-method-radio" */).then(c => wrapFunctional(c.default || c))
export const PaymentMethods = () => import('../../components/checkout/PaymentMethods.vue' /* webpackChunkName: "components/payment-methods" */).then(c => wrapFunctional(c.default || c))
export const SuccessOrderSummary = () => import('../../components/checkout/SuccessOrderSummary.vue' /* webpackChunkName: "components/success-order-summary" */).then(c => wrapFunctional(c.default || c))
export const CategoryOption = () => import('../../components/filter/CategoryOption.vue' /* webpackChunkName: "components/category-option" */).then(c => wrapFunctional(c.default || c))
export const FilterGroup = () => import('../../components/filter/FilterGroup.vue' /* webpackChunkName: "components/filter-group" */).then(c => wrapFunctional(c.default || c))
export const FilterOption = () => import('../../components/filter/FilterOption.vue' /* webpackChunkName: "components/filter-option" */).then(c => wrapFunctional(c.default || c))
export const Filters = () => import('../../components/filter/Filters.vue' /* webpackChunkName: "components/filters" */).then(c => wrapFunctional(c.default || c))
export const PriceOption = () => import('../../components/filter/PriceOption.vue' /* webpackChunkName: "components/price-option" */).then(c => wrapFunctional(c.default || c))
export const Accordion = () => import('../../components/Accordion.vue' /* webpackChunkName: "components/accordion" */).then(c => wrapFunctional(c.default || c))
export const AddToCart = () => import('../../components/AddToCart.vue' /* webpackChunkName: "components/add-to-cart" */).then(c => wrapFunctional(c.default || c))
export const AddressBook = () => import('../../components/AddressBook.vue' /* webpackChunkName: "components/address-book" */).then(c => wrapFunctional(c.default || c))
export const AddressSelect = () => import('../../components/AddressSelect.vue' /* webpackChunkName: "components/address-select" */).then(c => wrapFunctional(c.default || c))
export const AdminAreaTooltip = () => import('../../components/AdminAreaTooltip.vue' /* webpackChunkName: "components/admin-area-tooltip" */).then(c => wrapFunctional(c.default || c))
export const AdminEditButton = () => import('../../components/AdminEditButton.vue' /* webpackChunkName: "components/admin-edit-button" */).then(c => wrapFunctional(c.default || c))
export const ApiSelect = () => import('../../components/ApiSelect.vue' /* webpackChunkName: "components/api-select" */).then(c => wrapFunctional(c.default || c))
export const AppDialogMessage = () => import('../../components/AppDialogMessage.vue' /* webpackChunkName: "components/app-dialog-message" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumb = () => import('../../components/BreadCrumb.vue' /* webpackChunkName: "components/bread-crumb" */).then(c => wrapFunctional(c.default || c))
export const Button = () => import('../../components/Button.vue' /* webpackChunkName: "components/button" */).then(c => wrapFunctional(c.default || c))
export const CartCheckoutPopup = () => import('../../components/CartCheckoutPopup.vue' /* webpackChunkName: "components/cart-checkout-popup" */).then(c => wrapFunctional(c.default || c))
export const CheckButton = () => import('../../components/CheckButton.vue' /* webpackChunkName: "components/check-button" */).then(c => wrapFunctional(c.default || c))
export const CheckoutBar = () => import('../../components/CheckoutBar.vue' /* webpackChunkName: "components/checkout-bar" */).then(c => wrapFunctional(c.default || c))
export const CodeInputGroup = () => import('../../components/CodeInputGroup.vue' /* webpackChunkName: "components/code-input-group" */).then(c => wrapFunctional(c.default || c))
export const Container = () => import('../../components/Container.vue' /* webpackChunkName: "components/container" */).then(c => wrapFunctional(c.default || c))
export const CurrencySelector = () => import('../../components/CurrencySelector.vue' /* webpackChunkName: "components/currency-selector" */).then(c => wrapFunctional(c.default || c))
export const DespatchTime = () => import('../../components/DespatchTime.vue' /* webpackChunkName: "components/despatch-time" */).then(c => wrapFunctional(c.default || c))
export const DespatchTimeMobile = () => import('../../components/DespatchTimeMobile.vue' /* webpackChunkName: "components/despatch-time-mobile" */).then(c => wrapFunctional(c.default || c))
export const DiscountGiftCardsListing = () => import('../../components/DiscountGiftCardsListing.vue' /* webpackChunkName: "components/discount-gift-cards-listing" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../../components/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const EmailSignup = () => import('../../components/EmailSignup.vue' /* webpackChunkName: "components/email-signup" */).then(c => wrapFunctional(c.default || c))
export const FormInput = () => import('../../components/FormInput.vue' /* webpackChunkName: "components/form-input" */).then(c => wrapFunctional(c.default || c))
export const HorizontalSlider = () => import('../../components/HorizontalSlider.vue' /* webpackChunkName: "components/horizontal-slider" */).then(c => wrapFunctional(c.default || c))
export const Icon = () => import('../../components/Icon.vue' /* webpackChunkName: "components/icon" */).then(c => wrapFunctional(c.default || c))
export const ListLoading = () => import('../../components/ListLoading.vue' /* webpackChunkName: "components/list-loading" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const LoadingPips = () => import('../../components/LoadingPips.vue' /* webpackChunkName: "components/loading-pips" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const Modal = () => import('../../components/Modal.vue' /* webpackChunkName: "components/modal" */).then(c => wrapFunctional(c.default || c))
export const NoImage = () => import('../../components/NoImage.vue' /* webpackChunkName: "components/no-image" */).then(c => wrapFunctional(c.default || c))
export const Notification = () => import('../../components/Notification.vue' /* webpackChunkName: "components/notification" */).then(c => wrapFunctional(c.default || c))
export const OptionsMap = () => import('../../components/OptionsMap.vue' /* webpackChunkName: "components/options-map" */).then(c => wrapFunctional(c.default || c))
export const OptionsMapGenerator = () => import('../../components/OptionsMapGenerator.vue' /* webpackChunkName: "components/options-map-generator" */).then(c => wrapFunctional(c.default || c))
export const OrderAddress = () => import('../../components/OrderAddress.vue' /* webpackChunkName: "components/order-address" */).then(c => wrapFunctional(c.default || c))
export const OrderSummary = () => import('../../components/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c))
export const PaymentErrorBox = () => import('../../components/PaymentErrorBox.vue' /* webpackChunkName: "components/payment-error-box" */).then(c => wrapFunctional(c.default || c))
export const QuantityCartSelector = () => import('../../components/QuantityCartSelector.vue' /* webpackChunkName: "components/quantity-cart-selector" */).then(c => wrapFunctional(c.default || c))
export const QuantitySelector = () => import('../../components/QuantitySelector.vue' /* webpackChunkName: "components/quantity-selector" */).then(c => wrapFunctional(c.default || c))
export const RadioButton = () => import('../../components/RadioButton.vue' /* webpackChunkName: "components/radio-button" */).then(c => wrapFunctional(c.default || c))
export const RatingStars = () => import('../../components/RatingStars.vue' /* webpackChunkName: "components/rating-stars" */).then(c => wrapFunctional(c.default || c))
export const RatioImage = () => import('../../components/RatioImage.vue' /* webpackChunkName: "components/ratio-image" */).then(c => wrapFunctional(c.default || c))
export const ResponsiveImage = () => import('../../components/ResponsiveImage.vue' /* webpackChunkName: "components/responsive-image" */).then(c => wrapFunctional(c.default || c))
export const SearchNoResult = () => import('../../components/SearchNoResult.vue' /* webpackChunkName: "components/search-no-result" */).then(c => wrapFunctional(c.default || c))
export const SelectOption = () => import('../../components/SelectOption.vue' /* webpackChunkName: "components/select-option" */).then(c => wrapFunctional(c.default || c))
export const Sider = () => import('../../components/Sider.vue' /* webpackChunkName: "components/sider" */).then(c => wrapFunctional(c.default || c))
export const SiderHeader = () => import('../../components/SiderHeader.vue' /* webpackChunkName: "components/sider-header" */).then(c => wrapFunctional(c.default || c))
export const SiderProduct = () => import('../../components/SiderProduct.vue' /* webpackChunkName: "components/sider-product" */).then(c => wrapFunctional(c.default || c))
export const Skeleton = () => import('../../components/Skeleton.vue' /* webpackChunkName: "components/skeleton" */).then(c => wrapFunctional(c.default || c))
export const Sticky = () => import('../../components/Sticky.vue' /* webpackChunkName: "components/sticky" */).then(c => wrapFunctional(c.default || c))
export const ThemeImage = () => import('../../components/ThemeImage.vue' /* webpackChunkName: "components/theme-image" */).then(c => wrapFunctional(c.default || c))
export const ThemeResponsiveImage = () => import('../../components/ThemeResponsiveImage.vue' /* webpackChunkName: "components/theme-responsive-image" */).then(c => wrapFunctional(c.default || c))
export const Toggle = () => import('../../components/Toggle.vue' /* webpackChunkName: "components/toggle" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const VariantOptionItem = () => import('../../components/VariantOptionItem.vue' /* webpackChunkName: "components/variant-option-item" */).then(c => wrapFunctional(c.default || c))
export const ViewButton = () => import('../../components/ViewButton.vue' /* webpackChunkName: "components/view-button" */).then(c => wrapFunctional(c.default || c))
export const Account = () => import('../../containers/Account.vue' /* webpackChunkName: "components/account" */).then(c => wrapFunctional(c.default || c))
export const AddToBagFeedback = () => import('../../containers/AddToBagFeedback.vue' /* webpackChunkName: "components/add-to-bag-feedback" */).then(c => wrapFunctional(c.default || c))
export const Address = () => import('../../containers/Address.vue' /* webpackChunkName: "components/address" */).then(c => wrapFunctional(c.default || c))
export const BestSellers = () => import('../../containers/BestSellers.vue' /* webpackChunkName: "components/best-sellers" */).then(c => wrapFunctional(c.default || c))
export const CartDiscountCodeForm = () => import('../../containers/CartDiscountCodeForm.vue' /* webpackChunkName: "components/cart-discount-code-form" */).then(c => wrapFunctional(c.default || c))
export const ContactFormModal = () => import('../../containers/ContactFormModal.vue' /* webpackChunkName: "components/contact-form-modal" */).then(c => wrapFunctional(c.default || c))
export const Cookies = () => import('../../containers/Cookies.vue' /* webpackChunkName: "components/cookies" */).then(c => wrapFunctional(c.default || c))
export const GetNotifiedModal = () => import('../../containers/GetNotifiedModal.vue' /* webpackChunkName: "components/get-notified-modal" */).then(c => wrapFunctional(c.default || c))
export const GoogleTranslateBar = () => import('../../containers/GoogleTranslateBar.vue' /* webpackChunkName: "components/google-translate-bar" */).then(c => wrapFunctional(c.default || c))
export const HTMLWrapper = () => import('../../containers/HTMLWrapper.vue' /* webpackChunkName: "components/h-t-m-l-wrapper" */).then(c => wrapFunctional(c.default || c))
export const Login = () => import('../../containers/Login.vue' /* webpackChunkName: "components/login" */).then(c => wrapFunctional(c.default || c))
export const Notifications = () => import('../../containers/Notifications.vue' /* webpackChunkName: "components/notifications" */).then(c => wrapFunctional(c.default || c))
export const PhoneField = () => import('../../containers/PhoneField.vue' /* webpackChunkName: "components/phone-field" */).then(c => wrapFunctional(c.default || c))
export const RelatedProducts = () => import('../../containers/RelatedProducts.vue' /* webpackChunkName: "components/related-products" */).then(c => wrapFunctional(c.default || c))
export const Salebar = () => import('../../containers/Salebar.vue' /* webpackChunkName: "components/salebar" */).then(c => wrapFunctional(c.default || c))
export const StructuredData = () => import('../../containers/StructuredData.vue' /* webpackChunkName: "components/structured-data" */).then(c => wrapFunctional(c.default || c))
export const Wishlist = () => import('../../containers/Wishlist.vue' /* webpackChunkName: "components/wishlist" */).then(c => wrapFunctional(c.default || c))
export const WishlistForm = () => import('../../containers/WishlistForm.vue' /* webpackChunkName: "components/wishlist-form" */).then(c => wrapFunctional(c.default || c))
export const CurrencySelectorModal = () => import('../../containers/CurrencySelectorModal/index.vue' /* webpackChunkName: "components/currency-selector-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountGuestModal = () => import('../../containers/account/GuestModal.vue' /* webpackChunkName: "components/account-guest-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountLoginModal = () => import('../../containers/account/LoginModal.vue' /* webpackChunkName: "components/account-login-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountResetModal = () => import('../../containers/account/ResetModal.vue' /* webpackChunkName: "components/account-reset-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountSignupModal = () => import('../../containers/account/SignupModal.vue' /* webpackChunkName: "components/account-signup-modal" */).then(c => wrapFunctional(c.default || c))
export const AccountSocialLogin = () => import('../../containers/account/SocialLogin.vue' /* webpackChunkName: "components/account-social-login" */).then(c => wrapFunctional(c.default || c))
export const CollectionHeader = () => import('../../containers/collection/CollectionHeader.vue' /* webpackChunkName: "components/collection-header" */).then(c => wrapFunctional(c.default || c))
export const CollectionLink = () => import('../../containers/collection/CollectionLink.vue' /* webpackChunkName: "components/collection-link" */).then(c => wrapFunctional(c.default || c))
export const CollectionProductGallery = () => import('../../containers/collection/CollectionProductGallery.vue' /* webpackChunkName: "components/collection-product-gallery" */).then(c => wrapFunctional(c.default || c))
export const CollectionProductItem = () => import('../../containers/collection/CollectionProductItem.vue' /* webpackChunkName: "components/collection-product-item" */).then(c => wrapFunctional(c.default || c))
export const CollectionProductItemSkeleton = () => import('../../containers/collection/CollectionProductItemSkeleton.vue' /* webpackChunkName: "components/collection-product-item-skeleton" */).then(c => wrapFunctional(c.default || c))
export const CollectionReviewsSlider = () => import('../../containers/collection/CollectionReviewsSlider.vue' /* webpackChunkName: "components/collection-reviews-slider" */).then(c => wrapFunctional(c.default || c))
export const CollectionGenrePageCollectionLinks = () => import('../../containers/collection/GenrePageCollectionLinks.vue' /* webpackChunkName: "components/collection-genre-page-collection-links" */).then(c => wrapFunctional(c.default || c))
export const CollectionRelatedCollections = () => import('../../containers/collection/RelatedCollections.vue' /* webpackChunkName: "components/collection-related-collections" */).then(c => wrapFunctional(c.default || c))
export const CollectionSortBy = () => import('../../containers/collection/SortBy.vue' /* webpackChunkName: "components/collection-sort-by" */).then(c => wrapFunctional(c.default || c))
export const CollectionSortFilterContainer = () => import('../../containers/collection/SortFilterContainer.vue' /* webpackChunkName: "components/collection-sort-filter-container" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAmazon = () => import('../../containers/checkout/Amazon.vue' /* webpackChunkName: "components/checkout-amazon" */).then(c => wrapFunctional(c.default || c))
export const CheckoutApplePay = () => import('../../containers/checkout/ApplePay.vue' /* webpackChunkName: "components/checkout-apple-pay" */).then(c => wrapFunctional(c.default || c))
export const CheckoutApplePayWithJudoPay = () => import('../../containers/checkout/ApplePayWithJudoPay.vue' /* webpackChunkName: "components/checkout-apple-pay-with-judo-pay" */).then(c => wrapFunctional(c.default || c))
export const CheckoutAuthenticationForm = () => import('../../containers/checkout/AuthenticationForm.vue' /* webpackChunkName: "components/checkout-authentication-form" */).then(c => wrapFunctional(c.default || c))
export const CheckoutGooglePay = () => import('../../containers/checkout/GooglePay.vue' /* webpackChunkName: "components/checkout-google-pay" */).then(c => wrapFunctional(c.default || c))
export const CheckoutJudoPay = () => import('../../containers/checkout/JudoPay.vue' /* webpackChunkName: "components/checkout-judo-pay" */).then(c => wrapFunctional(c.default || c))
export const CheckoutKlarna = () => import('../../containers/checkout/Klarna.vue' /* webpackChunkName: "components/checkout-klarna" */).then(c => wrapFunctional(c.default || c))
export const CheckoutPaypal = () => import('../../containers/checkout/Paypal.vue' /* webpackChunkName: "components/checkout-paypal" */).then(c => wrapFunctional(c.default || c))
export const CheckoutSelectedPayment = () => import('../../containers/checkout/SelectedPayment.vue' /* webpackChunkName: "components/checkout-selected-payment" */).then(c => wrapFunctional(c.default || c))
export const DeliveryClickAndCollectModal = () => import('../../containers/delivery/ClickAndCollectModal.vue' /* webpackChunkName: "components/delivery-click-and-collect-modal" */).then(c => wrapFunctional(c.default || c))
export const DeliveryOpeningTimesModal = () => import('../../containers/delivery/OpeningTimesModal.vue' /* webpackChunkName: "components/delivery-opening-times-modal" */).then(c => wrapFunctional(c.default || c))
export const DeliveryShippingAddressModal = () => import('../../containers/delivery/ShippingAddressModal.vue' /* webpackChunkName: "components/delivery-shipping-address-modal" */).then(c => wrapFunctional(c.default || c))
export const ArticleFeedbackArticle = () => import('../../containers/article/FeedbackArticle.vue' /* webpackChunkName: "components/article-feedback-article" */).then(c => wrapFunctional(c.default || c))
export const ErrorContent = () => import('../../containers/error/ErrorContent.vue' /* webpackChunkName: "components/error-content" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../containers/footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FooterMenu = () => import('../../containers/footer/FooterMenu.vue' /* webpackChunkName: "components/footer-menu" */).then(c => wrapFunctional(c.default || c))
export const FooterPaymentLogos = () => import('../../containers/footer/FooterPaymentLogos.vue' /* webpackChunkName: "components/footer-payment-logos" */).then(c => wrapFunctional(c.default || c))
export const HeaderFreeShippingBar = () => import('../../containers/header/FreeShippingBar.vue' /* webpackChunkName: "components/header-free-shipping-bar" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../containers/header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderActivity = () => import('../../containers/header/HeaderActivity.vue' /* webpackChunkName: "components/header-activity" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownItem = () => import('../../containers/header/HeaderDropdownItem.vue' /* webpackChunkName: "components/header-dropdown-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderIconButton = () => import('../../containers/header/HeaderIconButton.vue' /* webpackChunkName: "components/header-icon-button" */).then(c => wrapFunctional(c.default || c))
export const HeaderMenu = () => import('../../containers/header/HeaderMenu.vue' /* webpackChunkName: "components/header-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearch = () => import('../../containers/header/HeaderSearch.vue' /* webpackChunkName: "components/header-search" */).then(c => wrapFunctional(c.default || c))
export const HeaderSubMenu = () => import('../../containers/header/HeaderSubMenu.vue' /* webpackChunkName: "components/header-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderSubMenuItem = () => import('../../containers/header/HeaderSubMenuItem.vue' /* webpackChunkName: "components/header-sub-menu-item" */).then(c => wrapFunctional(c.default || c))
export const HomepageBlogPostCard = () => import('../../containers/homepage/BlogPostCard.vue' /* webpackChunkName: "components/homepage-blog-post-card" */).then(c => wrapFunctional(c.default || c))
export const HomepageGenderSubCategories = () => import('../../containers/homepage/GenderSubCategories.vue' /* webpackChunkName: "components/homepage-gender-sub-categories" */).then(c => wrapFunctional(c.default || c))
export const HomepageSmoothExpandDiv = () => import('../../containers/homepage/SmoothExpandDiv.vue' /* webpackChunkName: "components/homepage-smooth-expand-div" */).then(c => wrapFunctional(c.default || c))
export const PagesBlog = () => import('../../containers/pages/Blog.vue' /* webpackChunkName: "components/pages-blog" */).then(c => wrapFunctional(c.default || c))
export const PagesCollection = () => import('../../containers/pages/Collection.vue' /* webpackChunkName: "components/pages-collection" */).then(c => wrapFunctional(c.default || c))
export const PagesPage = () => import('../../containers/pages/Page.vue' /* webpackChunkName: "components/pages-page" */).then(c => wrapFunctional(c.default || c))
export const PagesProduct = () => import('../../containers/pages/Product.vue' /* webpackChunkName: "components/pages-product" */).then(c => wrapFunctional(c.default || c))
export const ProductDeliveryAndReturns = () => import('../../containers/product/DeliveryAndReturns.vue' /* webpackChunkName: "components/product-delivery-and-returns" */).then(c => wrapFunctional(c.default || c))
export const ProductFinalProductGallery = () => import('../../containers/product/FinalProductGallery.vue' /* webpackChunkName: "components/product-final-product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductImageMagnifier = () => import('../../containers/product/ImageMagnifier.vue' /* webpackChunkName: "components/product-image-magnifier" */).then(c => wrapFunctional(c.default || c))
export const ProductItem = () => import('../../containers/product/ProductItem.vue' /* webpackChunkName: "components/product-item" */).then(c => wrapFunctional(c.default || c))
export const ProductItemTitleLink = () => import('../../containers/product/ProductItemTitleLink.vue' /* webpackChunkName: "components/product-item-title-link" */).then(c => wrapFunctional(c.default || c))
export const ProductList = () => import('../../containers/product/ProductList.vue' /* webpackChunkName: "components/product-list" */).then(c => wrapFunctional(c.default || c))
export const ProductOptionSelection = () => import('../../containers/product/ProductOptionSelection.vue' /* webpackChunkName: "components/product-option-selection" */).then(c => wrapFunctional(c.default || c))
export const ProductPrice = () => import('../../containers/product/ProductPrice.vue' /* webpackChunkName: "components/product-price" */).then(c => wrapFunctional(c.default || c))
export const ProductPriceMobile = () => import('../../containers/product/ProductPriceMobile.vue' /* webpackChunkName: "components/product-price-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductQuantity = () => import('../../containers/product/ProductQuantity.vue' /* webpackChunkName: "components/product-quantity" */).then(c => wrapFunctional(c.default || c))
export const ProductTabs = () => import('../../containers/product/ProductTabs.vue' /* webpackChunkName: "components/product-tabs" */).then(c => wrapFunctional(c.default || c))
export const ProductVariantSelection = () => import('../../containers/product/ProductVariantSelection.vue' /* webpackChunkName: "components/product-variant-selection" */).then(c => wrapFunctional(c.default || c))
export const ThemeAlternativePageHeader = () => import('../../containers/theme/AlternativePageHeader.vue' /* webpackChunkName: "components/theme-alternative-page-header" */).then(c => wrapFunctional(c.default || c))
export const ThemeProductCard = () => import('../../containers/theme/ProductCard.vue' /* webpackChunkName: "components/theme-product-card" */).then(c => wrapFunctional(c.default || c))
export const ThemeProductPopularCountryHomepage = () => import('../../containers/theme/ProductPopularCountryHomepage.vue' /* webpackChunkName: "components/theme-product-popular-country-homepage" */).then(c => wrapFunctional(c.default || c))
export const ThemeStackedProductCard = () => import('../../containers/theme/StackedProductCard.vue' /* webpackChunkName: "components/theme-stacked-product-card" */).then(c => wrapFunctional(c.default || c))
export const ThemeSaleProduct = () => import('../../containers/theme/ThemeSaleProduct.vue' /* webpackChunkName: "components/theme-sale-product" */).then(c => wrapFunctional(c.default || c))
export const WishlistSortByWishlist = () => import('../../containers/wishlist/SortByWishlist.vue' /* webpackChunkName: "components/wishlist-sort-by-wishlist" */).then(c => wrapFunctional(c.default || c))
export const WishlistSortFilterWishlist = () => import('../../containers/wishlist/SortFilterWishlist.vue' /* webpackChunkName: "components/wishlist-sort-filter-wishlist" */).then(c => wrapFunctional(c.default || c))
export const AccountFormsGuestForm = () => import('../../containers/account/forms/GuestForm.vue' /* webpackChunkName: "components/account-forms-guest-form" */).then(c => wrapFunctional(c.default || c))
export const AccountFormsLoginForm = () => import('../../containers/account/forms/LoginForm.vue' /* webpackChunkName: "components/account-forms-login-form" */).then(c => wrapFunctional(c.default || c))
export const AccountFormsResetForm = () => import('../../containers/account/forms/ResetForm.vue' /* webpackChunkName: "components/account-forms-reset-form" */).then(c => wrapFunctional(c.default || c))
export const AccountFormsSignupForm = () => import('../../containers/account/forms/SignupForm.vue' /* webpackChunkName: "components/account-forms-signup-form" */).then(c => wrapFunctional(c.default || c))
export const CollectionScoreMobile = () => import('../../containers/collection/CollectionVariantModal/CollectionScoreMobile.vue' /* webpackChunkName: "components/collection-score-mobile" */).then(c => wrapFunctional(c.default || c))
export const CollectionVariantGalllery = () => import('../../containers/collection/CollectionVariantModal/CollectionVariantGalllery.vue' /* webpackChunkName: "components/collection-variant-galllery" */).then(c => wrapFunctional(c.default || c))
export const CollectionVariantModal = () => import('../../containers/collection/CollectionVariantModal/CollectionVariantModal.vue' /* webpackChunkName: "components/collection-variant-modal" */).then(c => wrapFunctional(c.default || c))
export const CheckoutExpressApplePay = () => import('../../containers/checkout/express/ExpressApplePay.vue' /* webpackChunkName: "components/checkout-express-apple-pay" */).then(c => wrapFunctional(c.default || c))
export const CheckoutExpressGooglePay = () => import('../../containers/checkout/express/ExpressGooglePay.vue' /* webpackChunkName: "components/checkout-express-google-pay" */).then(c => wrapFunctional(c.default || c))
export const DeliveryComponentsStoreSelect = () => import('../../containers/delivery/components/StoreSelect.vue' /* webpackChunkName: "components/delivery-components-store-select" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItem = () => import('../../containers/header/HeaderCartItems/HeaderCartItem.vue' /* webpackChunkName: "components/header-cart-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItems = () => import('../../containers/header/HeaderCartItems/HeaderCartItems.vue' /* webpackChunkName: "components/header-cart-items" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItemsDesktop = () => import('../../containers/header/HeaderCartItems/HeaderCartItemsDesktop.vue' /* webpackChunkName: "components/header-cart-items-desktop" */).then(c => wrapFunctional(c.default || c))
export const HeaderCartItemsMobile = () => import('../../containers/header/HeaderCartItems/HeaderCartItemsMobile.vue' /* webpackChunkName: "components/header-cart-items-mobile" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewForm = () => import('../../containers/product/ProductReview/ProductReviewForm.vue' /* webpackChunkName: "components/product-review-form" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewRatingStars = () => import('../../containers/product/ProductReview/ProductReviewRatingStars.vue' /* webpackChunkName: "components/product-review-rating-stars" */).then(c => wrapFunctional(c.default || c))
export const ProductReviews = () => import('../../containers/product/ProductReview/ProductReviews.vue' /* webpackChunkName: "components/product-reviews" */).then(c => wrapFunctional(c.default || c))
export const ProductReviewsSlider = () => import('../../containers/product/ProductReview/ProductReviewsSlider.vue' /* webpackChunkName: "components/product-reviews-slider" */).then(c => wrapFunctional(c.default || c))
export const ThemeHeaderCategoriesMobile = () => import('../../containers/theme/HeaderCategoriesMobile/HeaderCategoriesMobile.vue' /* webpackChunkName: "components/theme-header-categories-mobile" */).then(c => wrapFunctional(c.default || c))
export const ThemeHeaderSubCategoriesMobile = () => import('../../containers/theme/HeaderCategoriesMobile/HeaderSubCategoriesMobile.vue' /* webpackChunkName: "components/theme-header-sub-categories-mobile" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageBanner = () => import('../../containers/theme/HomepageBanner/HomepageBanner.vue' /* webpackChunkName: "components/theme-homepage-banner" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageBestSeller = () => import('../../containers/theme/HomepageBestSeller/HomepageBestSeller.vue' /* webpackChunkName: "components/theme-homepage-best-seller" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageBestSellerFirst = () => import('../../containers/theme/HomepageBestSeller/HomepageBestSellerFirst.vue' /* webpackChunkName: "components/theme-homepage-best-seller-first" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageBestSellerProduct = () => import('../../containers/theme/HomepageBestSeller/HomepageBestSellerProduct.vue' /* webpackChunkName: "components/theme-homepage-best-seller-product" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageBestSellerSecond = () => import('../../containers/theme/HomepageBestSeller/HomepageBestSellerSecond.vue' /* webpackChunkName: "components/theme-homepage-best-seller-second" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageBlogCard = () => import('../../containers/theme/HomepageBlogs/HomepageBlogCard.vue' /* webpackChunkName: "components/theme-homepage-blog-card" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageBlogs = () => import('../../containers/theme/HomepageBlogs/HomepageBlogs.vue' /* webpackChunkName: "components/theme-homepage-blogs" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageCategories = () => import('../../containers/theme/HomepageCategories/HomepageCategories.vue' /* webpackChunkName: "components/theme-homepage-categories" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageCompany = () => import('../../containers/theme/HomepageCompany/HomepageCompany.vue' /* webpackChunkName: "components/theme-homepage-company" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageHeaderCategoriesDropdown = () => import('../../containers/theme/HomepageHeader/CategoriesDropdown.vue' /* webpackChunkName: "components/theme-homepage-header-categories-dropdown" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageHeaderCategoriesDropdownMeganav = () => import('../../containers/theme/HomepageHeader/CategoriesDropdownMeganav.vue' /* webpackChunkName: "components/theme-homepage-header-categories-dropdown-meganav" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageHeaderCategoriesDropdownSpot = () => import('../../containers/theme/HomepageHeader/CategoriesDropdownSpot.vue' /* webpackChunkName: "components/theme-homepage-header-categories-dropdown-spot" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageHeader = () => import('../../containers/theme/HomepageHeader/HomepageHeader.vue' /* webpackChunkName: "components/theme-homepage-header" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageReviews = () => import('../../containers/theme/HomepageReviews/HomepageReviews.vue' /* webpackChunkName: "components/theme-homepage-reviews" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageReviewsCard = () => import('../../containers/theme/HomepageReviews/HomepageReviewsCard.vue' /* webpackChunkName: "components/theme-homepage-reviews-card" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageSpotlightFirst = () => import('../../containers/theme/HomepageSpotlight/HomepageSpotlightFirst.vue' /* webpackChunkName: "components/theme-homepage-spotlight-first" */).then(c => wrapFunctional(c.default || c))
export const ThemeHomepageSpotlightSecond = () => import('../../containers/theme/HomepageSpotlight/HomepageSpotlightSecond.vue' /* webpackChunkName: "components/theme-homepage-spotlight-second" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
