export default function ({ app }) {
  const data = {
    currency: 'GBP',
    country: 'en',
    alpha2: 'GB',
    detectLanguageModal: false,
    shippingCountry: 239,
  }

  app.store.commit('settings/setLanguage', data)

  app.i18n.onBeforeLanguageSwitch = (
    oldLocale,
    newLocale,
    isInitialSetup,
    context
  ) => {
    console.log(oldLocale, newLocale, isInitialSetup)
  }
  // onLanguageSwitched called right after a new locale has been set
  app.i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    console.log(oldLocale, newLocale)
  }
}
