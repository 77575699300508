export default ({ app }) => {
  if (process.client) {
    window.dataLayer = window.dataLayer || []
    function gtag() {
      try {
        window.dataLayer.push(arguments)
      } catch (err) {
        console.error(err)
      }
    }

    window.gtag = gtag

    window.gtag('js', new Date())
    window.gtag('config', 'AW-980964690', {
      allow_enhanced_conversions: true,
    })

    app.router.afterEach((to, from) => {
      window.gtag('event', 'page_view', {
        page_path: to.fullPath,
        page_title: document.title,
      })
    })
  }
}
