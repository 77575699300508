
import ResetForm from '@/containers/account/forms/ResetForm.vue'
export default {
  components: {
    ResetForm,
  },
  data() {
    return {
      modal: {
        signup: true,
      },
    }
  },
  methods: {
    close() {
      this.$emit('close', true)
    },
  },
}
