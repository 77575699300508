
import icons from '@/assets/icons'
export default {
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: '',
    },
    set: {
      type: String,
      default: 'far',
    },
    spin: {
      type: Boolean,
      default: false,
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icons,
    }
  },
  computed: {
    sizeClass() {
      if (this.size) {
        return `size-${this.size}`
      }

      return null
    },
  },
}
