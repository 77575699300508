import { browserLanguageDetect } from '~/plugins/utilities'

export const state = () => ({
  currency: 'GBP',
  country: 'EN',
  detectLanguageModal: false,
  shippingCountry: 239,
})

export const getters = {
  currency(state) {
    return state.currency
  },
  country(state) {
    return state.country
  },
  detectLanguageModal(state) {
    return state.detectLanguageModal
  },
  shippingCountry(state) {
    return state.shippingCountry
  },
}

export const mutations = {
  setLanguage(state, data) {
    this.$cookies.set('detectLanguageModal', data.detectLanguageModal)
    this.$cookies.set('currency', data.currency)
    this.$cookies.set('country', data.country)
    this.$cookies.set('shippingCountry', data.shippingCountry)
    this.$cookies.set('alpha2', data.alpha2)

    state.currency = data.currency
    state.detectLanguageModal = data.detectLanguageModal
    state.shippingCountry = data.shippingCountry
    state.country = data.country
    state.alpha2 = data.alpha2
  },
}

export const actions = {
  browserInit({ commit }, app) {
    const selectedLocale = app.i18n.locale

    const selectedCountry = this.$i18n.locales.filter((locale) => {
      return locale.code === selectedLocale
    })

    const country = selectedCountry[0].code
    const alpha2 = selectedCountry[0].country.alpha2
    const currency = selectedCountry[0].currencyCode
    const detectLanguageModal =
      this.$cookies.get('detectLanguageModal') || false
    const shippingCountry = selectedCountry[0].id

    const data = {
      currency,
      country,
      alpha2,
      detectLanguageModal,
      shippingCountry,
    }

    commit('setLanguage', data)
  },
  languageSetter({ commit }, data) {
    commit('setLanguage', data)
  },
}
