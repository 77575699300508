
import { parseErrors } from '@/plugins/utilities'
import { loginSignInGTM } from '~/plugins/gtm-helper'

const emptyData = {
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password_confirmation: '',
}

export default {
  middleware: 'guest',
  props: {
    email: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'normal',
      validator: (value) => ['normal', 'checkout', 'account'].includes(value),
    },
  },
  data() {
    return {
      data: { ...emptyData, email: this.email },
      error: null,
      loading: false,
    }
  },

  head() {
    return {
      title: this.$t('auth.pageTitle'),
    }
  },

  mounted() {
    const { data } = this.$route.params
    this.data = { ...this.data, ...data }
  },

  methods: {
    async submit() {
      this.loading = true
      this.errors = null
      const { data } = this
      await this.$axios
        .$post('auth/signUp', data)
        .then((response) => {
          this.data = { ...emptyData }
          loginSignInGTM('signin', response.data.token)
          this.$auth.setUserToken(response.data.token).then(() => {
            if (this.mode === 'checkout') {
              this.$router.push(this.localePath('/checkout/info'))
            } else if (this.mode === 'account') {
              this.$router.push(this.localePath('/account'))
            } else {
              this.$router.push(this.localePath('/'))
            }
          })
          this.$emit('close')
        })
        .catch((error) => {
          this.error =
            error.response.data.errors &&
            parseErrors(error.response.data.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
