
export default {
  props: {
    text: {
      type: String,
      default: null,
    },
    value: {
      type: Boolean,
      default: false,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    // Check or toggle position
    right: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change(e) {
      this.$emit('input', e.target.checked)
    },
  },
}
