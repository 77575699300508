
export default {
  props: {
    title: {
      type: String,
      default: '',
      required: false,
    },
    message: {
      type: String,
      default: '',
      required: false,
    },
    type: {
      type: String,
      default: 'success',
      required: false,
      validator: (value) => ['success', 'error'].includes(value),
    },
    confirmButton: {
      type: String,
      default: null,
      required: false,
    },
    cancelButton: {
      type: String,
      default: null,
      required: false,
    },
    maxWidth: {
      type: String,
      default: '610',
      required: false,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: true,
      timeout: null,
    }
  },
  computed: {
    iconTypes() {
      switch (this.type) {
        case 'success':
          return {
            set: 'fas',
            name: 'check-circle',
          }
        case 'error': {
          return {
            set: 'fas',
            name: 'exclamation-circle',
          }
        }
        default:
          return {
            set: 'far',
            name: 'exclamation-circle',
          }
      }
    },
  },
  mounted() {
    this.startTime()
  },
  methods: {
    startTime() {
      this.timeout = setTimeout(() => {
        this.cancel()
      }, 3000)
    },
    clearTime() {
      clearTimeout(this.timeout)
    },
    handleClickOutside() {
      this.cancel()
    },
    confirm() {
      this.$router.push('/cart')
    },
    cancel() {
      this.show = false
    },
  },
}
