import { cartProductForDataLayer } from '@/plugins/utilities'

/**
 * GTM Product Impression
 * @param {Object} gtm GTM instance
 * @param {Object} data { products <Array> }
 */
export const productImpression = (data) => {
  try {
    window.dataLayer.push({
      event: 'productImpression',
      ecommerce: {
        currencyCode:
          data.products && data.products.length
            ? data.products[0].price_currency
            : 'GBP',
        impressions: data.products.map((product, index) => ({
          id: product.product_id || product.id,
          name: product.title,
          price: product.price,
          variant: product.product_variant_id || product.id,
          position: index + 1,
          list: data.list,
        })),
      },
    })
  } catch (err) {
    console.error(err)
  }
}

/**
 * GTM Detail View
 * @param {Object} gtm GTM instance
 * @param {Object} data { product }
 */
export const detailView = (data) => {
  try {
    window.dataLayer.push({
      event: 'detailView',
      ecommerce: {
        currencyCode: data.product.price_currency || 'GBP',
        detail: {
          products: [
            {
              id: data.product.id, // Name or ID is required.
              name: data.product.title,
              price: data.product.price,
              variant: data.product.product_variant_id,
            },
          ],
        },
      },
    })
  } catch (err) {
    console.error(err)
  }
}

/**
 * GTM Product Click
 * @param {Object} gtm { this.$gtm }
 * @param {Object} data { product, index }
 * @param {String} list actionField list name
 */
export const productClick = ({ product, index }, list) => {
  try {
    window.dataLayer.push({
      event: 'productClick',
      ecommerce: {
        currencyCode: product.price_currency || 'GBP',
        click: {
          actionField: { list },
          products: [
            {
              id: product.id,
              name: product.title,
              price: product.price,
              variant: product.product_variant_id,
              position: index,
            },
          ],
        },
      },
    })
  } catch (err) {
    console.error(err)
  }
}

/**
 * GTM Checkout Step
 * @param {Object} gtm GTM instance
 * @param {Object} param1 { step, products <optional> }
 */
export const checkoutStep = ({ step, products, option, currency }) => {
  try {
    const checkout = {
      actionField: { step },
    }

    if (products) {
      checkout.products = products.map(cartProductForDataLayer)
    }

    if (option) {
      checkout.actionField.option = option
    }

    window.dataLayer.push({
      event: 'checkout',
      ecommerce: {
        currencyCode: currency,
        checkout,
      },
    })
  } catch (err) {
    console.error(err)
  }
}

/**
 * GTM Checkout Option
 * @param {Object} gtm GTM instance
 * @param {Object} param1 { step, option }
 */
export const checkoutOption = ({ step, option }) => {
  try {
    window.dataLayer.push({
      event: 'checkoutOption',
      ecommerce: {
        checkout_option: {
          actionField: {
            step,
            option,
          },
        },
      },
    })
  } catch (err) {
    console.error(err)
  }
}

/**
 * GTM Purchase
 * @param {Object} gtm GTM instance
 * @param {Object} param1 { order, orderProducts }
 */
export const purchaseGtm = ({ order, orderProducts }) => {
  try {
    const purchaseData = {
      ecommerce: {
        currencyCode: order.currency || 'GBP',
        purchase: {
          actionField: {
            id: order.id,
            tax: order.tax_total,
            shipping: order.shipping_total,
            coupon: order.discount_code,
            revenue: order.total,
            payment_type: order.string_payment_method,
          },
          products: orderProducts.map(cartProductForDataLayer),
        },
      },
    }

    try {
      window.dataLayer.push({
        event: 'purchase',
        ...purchaseData,
      })
    } catch (err) {
      console.error(err)
    }
  } catch (err) {
    console.error(err)
  }

  try {
    const orderData = {
      send_to: 'AW-980964690/2irtCLG-pJkZENKq4dMD',
      transaction_id: order.id,
      value: order.total,
      currency: order.currency,
      email: order.email,
      phone: order.phone,
      first_name: order.shippingOrderAddress?.first_name,
      last_name: order.shippingOrderAddress?.last_name,
      address1: order.shippingOrderAddress?.address_1,
      address2: order.shippingOrderAddress?.address_2,
      city: order.shippingOrderAddress?.city,
      country: order.shippingOrderAddress?.country?.name,
      countryCode: order.shippingOrderAddress?.country?.code,
      province: order.shippingOrderAddress?.province,
      provinceCode: order.shippingOrderAddress?.province,
      zip: order.shippingOrderAddress?.province?.zip,
      user_data: {
        email_address: order.email,
      },
    }

    try {
      window.dataLayer.push({
        event: 'conversion',
        ...orderData,
      })
    } catch (err) {
      try {
        this.$sentry.captureException(err)
      } catch (err) {
        console.error(err)
      }

      console.error(err)
    }
  } catch (err) {
    try {
      this.$sentry.captureException(err)
    } catch (err) {
      console.error(err)
    }

    console.error(err)
  }
}

/**
 * GTM Virtual Page View
 * @param {Object} gtm GTM instance
 * @param {Object} route Page route instance
 * @param {String} modelType Page model <'product' | 'collection' | 'page'>
 * @param {String} pageTitle document.title
 */
export const virtualPageView = (route, modelType, pageTitle) => {
  try {
    let pageType
    switch (modelType) {
      case 'product':
        pageType = 'Product Page'
        break
      case 'collection':
        pageType = 'Collection Page'
        break
      case 'page':
        pageType = 'Other'
        break
      default:
        pageType = modelType
        break
    }

    window.dataLayer.push({
      event: 'virtualPageView',
      pageType,
      pageUrl: route.fullPath,
      pageTitle,
    })
  } catch (err) {
    console.error(err)
  }
}

/**
 * GTM Product List Page View
 * @param {Object} gtm GTM instance
 * @param {Number} pageNumber Loaded page number
 * @param {Number} totalPages Total page count
 */
export const productListPageView = (pageNumber, totalPages) => {
  try {
    window.dataLayer.push({
      event: 'productListPageView',
      pageNumber,
      totalPages,
    })
  } catch (err) {
    console.error(err)
  }
}

/**
 * GTM Sign in page
 * @param {Object} gtm GTM instance
 * @param {String} event Event type for GTM
 * @param {String} userId Data
 */
export const loginSignInGTM = (event, userId) => {
  try {
    window.dataLayer.push({
      event,
      userId,
    })
  } catch (err) {
    console.error(err)
  }
}
