import { render, staticRenderFns } from "./ResetModal.vue?vue&type=template&id=b09534e4&scoped=true"
import script from "./ResetModal.vue?vue&type=script&lang=js"
export * from "./ResetModal.vue?vue&type=script&lang=js"
import style0 from "./ResetModal.vue?vue&type=style&index=0&id=b09534e4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b09534e4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/opt/atlassian/pipelines/agent/build/components/Icon.vue').default,Button: require('/opt/atlassian/pipelines/agent/build/components/Button.vue').default,Modal: require('/opt/atlassian/pipelines/agent/build/components/Modal.vue').default})
