import IconRegularQuestionCircle from '@/assets/icons/solid/question-circle.svg?raw'
import IconSolidBell from '@/assets/icons/solid/bell.svg?raw'
import IconSolidSearch from '@/assets/icons/solid/search.svg?raw'
import IconSolidUser from '@/assets/icons/solid/user.svg?raw'
import IconSolidTimes from '@/assets/icons/solid/times.svg?raw'
import IconSolidStar from '@/assets/icons/solid/star.svg?raw'
import IconSolidSpinner from '@/assets/icons/solid/spinner.svg?raw'
import IconSolidHeart from '@/assets/icons/solid/heart.svg?raw'
import IconSolidCircle from '@/assets/icons/solid/circle.svg?raw'
import IconSolidPhone from '@/assets/icons/solid/phone.svg?raw'
import IconSolidShoppingCart from '@/assets/icons/solid/shopping-cart.svg?raw'
import IconSolidCheckCircle from '@/assets/icons/solid/check-circle.svg?raw'
import IconSolidExclamationCircle from '@/assets/icons/solid/exclamation-circle.svg?raw'
import IconSolidBarsSort from '@/assets/icons/solid/bars-sort-solid.svg?raw'
import IconSolidFilter from '@/assets/icons/solid/filter.svg?raw'
import IconSolidBoxDollar from '@/assets/icons/regular/box-dollar-solid.svg?raw'
import IconSolidHandHoldingHand from '@/assets/icons/regular/hand-holding-hand-solid.svg?raw'
import IconSolidLocationSmile from '@/assets/icons/regular/location-smile-solid.svg?raw'
import IconSolidTruck from '@/assets/icons/solid/truck-solid.svg?raw'
import IconSolidRotateLeft from '@/assets/icons/solid/rotate-left-solid.svg?raw'
import IconSolidBoxOpen from '@/assets/icons/solid/box-open-full-solid.svg?raw'
import IconSolidUserNew from '@/assets/icons/solid/user-solid.svg?raw'
import IconSolidHandHoldingHandDollar from '@/assets/icons/solid/hand-holding-circle-dollar-solid.svg?raw'
import IconSolidFileCircleCheck from '@/assets/icons/solid/file-circle-check-solid.svg?raw'
import IconSolidEnvelope from '@/assets/icons/solid/envelope.svg?raw'
import IconSolidXmark from '@/assets/icons/solid/xmark-solid.svg?raw'
import IconSolidInfoCircle from '@/assets/icons/solid/info-circle.svg?raw'
import IconSolidHandHoldingBox from '@/assets/icons/solid/hand-holding-box.svg?raw'
import IconSolidRotate from '@/assets/icons/solid/rotate-solid.svg?raw'
import IconSolidLocationDot from '@/assets/icons/solid/location-dot.svg?raw'
import IconSolidLocationArrow from '@/assets/icons/solid/location-arrow.svg?raw'
import IconSolidClock from '@/assets/icons/solid/clock.svg?raw'
import IconSolidCouch from '@/assets/icons/solid/couch.svg?raw'
import IconSolidArrowRight from '@/assets/icons/solid/arrow-right.svg?raw'
import IconSolidChristmasSolid from '@/assets/icons/solid/star-christmas.svg?raw'
import IconSolidCleaning from '@/assets/icons/solid/cleaning.svg?raw'
import IconSolidClothing from '@/assets/icons/solid/clothing.svg?raw'
import IconSolidDiy from '@/assets/icons/solid/diy.svg?raw'
import IconSolidHome from '@/assets/icons/solid/home.svg?raw'
import IconSolidSale from '@/assets/icons/solid/sale.svg?raw'

import IconRegularShoppingBag from '@/assets/icons/regular/shopping-bag.svg?raw'
import IconRegularShoppingCart from '@/assets/icons/regular/shopping-cart.svg?raw'
import IconRegularSearch from '@/assets/icons/regular/search.svg?raw'
import IconRegularUser from '@/assets/icons/regular/user.svg?raw'
import IconRegularHeart from '@/assets/icons/regular/heart.svg?raw'
import IconRegularTimes from '@/assets/icons/regular/times.svg?raw'
import IconRegularStar from '@/assets/icons/regular/star.svg?raw'
import IconRegularChevronDown from '@/assets/icons/regular/chevron-down.svg?raw'
import IconRegularChevronUp from '@/assets/icons/regular/chevron-up.svg?raw'
import IconRegularChevronRight from '@/assets/icons/regular/chevron-right.svg?raw'
import IconRegularChevronLeft from '@/assets/icons/regular/chevron-left.svg?raw'
import IconRegularUndoAlt from '@/assets/icons/regular/undo-alt.svg?raw'
import IconRegularAt from '@/assets/icons/regular/at.svg?raw'
import IconRegularSpinner from '@/assets/icons/regular/spinner.svg?raw'
import IconRegularTractor from '@/assets/icons/regular/tractor.svg?raw'
import IconRegularTrailer from '@/assets/icons/regular/trailer.svg?raw'
import IconRegularMinus from '@/assets/icons/regular/minus.svg?raw'
import IconRegularPlus from '@/assets/icons/regular/plus.svg?raw'
import IconRegularBars from '@/assets/icons/regular/bars.svg?raw'
import IconRegularUndo from '@/assets/icons/regular/undo.svg?raw'
import IconRegularCheck from '@/assets/icons/regular/check.svg?raw'
import IconRegularCheckSquare from '@/assets/icons/regular/check-square.svg?raw'
import IconRegularSquareO from '@/assets/icons/regular/square.svg?raw'
import IconRegularCircleSquare from '@/assets/icons/regular/check-circle.svg?raw'
import IconRegularCircle from '@/assets/icons/regular/circle.svg?raw'
import IconRegularArrowLeft from '@/assets/icons/regular/arrow-left.svg?raw'
import IconRegularArrowUp from '@/assets/icons/regular/arrow-up.svg?raw'
import IconRegularArrowDown from '@/assets/icons/regular/arrow-down.svg?raw'
import IconRegularTrashAlt from '@/assets/icons/regular/trash-alt.svg?raw'
import IconRegularPen from '@/assets/icons/regular/pen.svg?raw'
import IconRegularEdit from '@/assets/icons/regular/edit.svg?raw'
import IconRegularInfoCircle from '@/assets/icons/regular/info-circle.svg?raw'
import IconRegularHome from '@/assets/icons/regular/home.svg?raw'
import IconRegularTruck from '@/assets/icons/regular/truck.svg?raw'
import IconRegularTruckLoading from '@/assets/icons/regular/truck-loading.svg?raw'
import IconRegularTruckFast from '@/assets/icons/regular/truck-fast-sharp-solid.svg?raw'
import IconRegularClipboardCheck from '@/assets/icons/regular/clipboard-check.svg?raw'
import IconRegularBoxOpen from '@/assets/icons/regular/box-open.svg?raw'
import IconRegularForklift from '@/assets/icons/regular/forklift.svg?raw'
import IconRegularRuler from '@/assets/icons/regular/ruler.svg?raw'
import IconRegularTrash from '@/assets/icons/regular/trash.svg?raw'
import IconRegularThumbsUp from '@/assets/icons/regular/thumbs-up.svg?raw'
import IconRegularThumbsDown from '@/assets/icons/regular/thumbs-down.svg?raw'
import IconRegularCopy from '@/assets/icons/regular/copy.svg?raw'

import IconLigthTimes from '@/assets/icons/light/times.svg?raw'
import IconLigthStar from '@/assets/icons/light/star.svg?raw'
import IconLightEnvelope from '@/assets/icons/light/envelope.svg?raw'
import IconLightPhone from '@/assets/icons/light/phone.svg?raw'
import IconLightSpinner from '@/assets/icons/light/spinner.svg?raw'
import IconLightTractor from '@/assets/icons/light/tractor.svg?raw'
import IconLightPlus from '@/assets/icons/light/plus.svg?raw'
import IconLightChevronUp from '@/assets/icons/light/chevron-up.svg?raw'
import IconLightChevronDown from '@/assets/icons/light/chevron-down.svg?raw'
import IconLightClock from '@/assets/icons/light/clock.svg?raw'
import IconLightShippingTimed from '@/assets/icons/light/shipping-timed.svg?raw'
import IconLightCheck from '@/assets/icons/light/check.svg?raw'
import IconLightMapMarker from '@/assets/icons/light/map-marker.svg?raw'
import IconLightExclamationCircle from '@/assets/icons/light/exclamation-circle.svg?raw'
import IconLightShareAlt from '@/assets/icons/light/share-alt.svg?raw'
import IconLightFrown from '@/assets/icons/light/frown.svg?raw'
import IconLightUndo from '@/assets/icons/light/undo.svg?raw'
import IconLightTag from '@/assets/icons/light/tag.svg?raw'
import IconLightRaindrops from '@/assets/icons/light/raindrops.svg?raw'
import IconLightSearch from '@/assets/icons/light/search.svg?raw'
import IconLightChevronLeft from '@/assets/icons/light/chevron-left.svg?raw'
import IconLightHandHoldingBox from '@/assets/icons/light/hand-holding-box.svg?raw'
import IconLightLocationCircle from '@/assets/icons/light/circle-location-arrow-light.svg?raw'
import IconLightTruckClock from '@/assets/icons/light/truck-clock-light.svg?raw'

import IconBrandInstagram from '@/assets/icons/brands/instagram.svg?raw'
import IconBrandFacebook from '@/assets/icons/brands/facebook.svg?raw'
import IconBrandTwitter from '@/assets/icons/brands/twitter.svg?raw'
import IconBrandPinterest from '@/assets/icons/brands/pinterest.svg?raw'
import IconBrandWhatsapp from '@/assets/icons/brands/whatsapp.svg?raw'
import IconBrandGoogle from '@/assets/icons/brands/google.svg?raw'
import IconBrandAmazon from '@/assets/icons/brands/amazon.svg?raw'

import IconColoredFacebook from '@/assets/icons/colored/facebook.svg?raw'
import IconColoredGoogle from '@/assets/icons/colored/google.svg?raw'
import IconColoredAmazon from '@/assets/icons/colored/amazon.svg?raw'
import IconColoredInstagram from '@/assets/icons/colored/instagram.svg?raw'
import IconColoredTiktok from '@/assets/icons/colored/tiktok.svg?raw'

import IconPaymentAmazon from '@/assets/icons/payment-methods/amazon-49-534000.svg?raw'
import IconPaymentApplePay from '@/assets/icons/payment-methods/apple-pay-1-534001.svg?raw'
import IconPaymentCirrus from '@/assets/icons/payment-methods/cirrus-1-534004.svg?raw'
import IconPaymentDiners from '@/assets/icons/payment-methods/diners-3-534008.svg?raw'
import IconPaymentDiscover from '@/assets/icons/payment-methods/discover-17-534011.svg?raw'
import IconPaymentGooglePay from '@/assets/icons/payment-methods/google-wallet-2-534014.svg?raw'
import IconPaymentJCB from '@/assets/icons/payment-methods/jcb-1-534017.svg?raw'
import IconPaymentMaestro from '@/assets/icons/payment-methods/maestro-534018.svg?raw'
import IconPaymentMastercard from '@/assets/icons/payment-methods/mastercard-17-534015.svg?raw'
import IconPaymentPayPal from '@/assets/icons/payment-methods/paypal-36-534020.svg?raw'
import IconPaymentVisa from '@/assets/icons/payment-methods/visa-21-534034.svg?raw'
import IconPaymentVisaElectron from '@/assets/icons/payment-methods/visa-22-534035.svg?raw'

export default {
  fas: {
    'question-circle': IconRegularQuestionCircle,
    bell: IconSolidBell,
    search: IconSolidSearch,
    user: IconSolidUser,
    times: IconSolidTimes,
    star: IconSolidStar,
    spinner: IconSolidSpinner,
    'chevron-down': IconRegularChevronDown,
    heart: IconSolidHeart,
    circle: IconSolidCircle,
    phone: IconSolidPhone,
    'shopping-cart': IconSolidShoppingCart,
    'check-circle': IconSolidCheckCircle,
    'exclamation-circle': IconSolidExclamationCircle,
    'bars-sort': IconSolidBarsSort,
    filter: IconSolidFilter,
    'box-dollar': IconSolidBoxDollar,
    'hand-holding-hand': IconSolidHandHoldingHand,
    'location-smile': IconSolidLocationSmile,
    truck: IconSolidTruck,
    'rotate-left': IconSolidRotateLeft,
    'box-open': IconSolidBoxOpen,
    'user-new': IconSolidUserNew,
    'hand-holding-circle-dollar': IconSolidHandHoldingHandDollar,
    'file-circle-check': IconSolidFileCircleCheck,
    envelope: IconSolidEnvelope,
    xmark: IconSolidXmark,
    'info-circle': IconSolidInfoCircle,
    'hand-holding-box': IconSolidHandHoldingBox,
    rotate: IconSolidRotate,
    'location-dot': IconSolidLocationDot,
    'location-arrow': IconSolidLocationArrow,
    clock: IconSolidClock,
    couch: IconSolidCouch,
    'arrow-right': IconSolidArrowRight,
    'star-christmas': IconSolidChristmasSolid,
    cleaning: IconSolidCleaning,
    clothing: IconSolidClothing,
    diy: IconSolidDiy,
    home: IconSolidHome,
    sale: IconSolidSale,
  },
  far: {
    'shopping-bag': IconRegularShoppingBag,
    'shopping-cart': IconRegularShoppingCart,
    search: IconRegularSearch,
    user: IconRegularUser,
    heart: IconRegularHeart,
    times: IconRegularTimes,
    star: IconRegularStar,
    'chevron-down': IconRegularChevronDown,
    'chevron-up': IconRegularChevronUp,
    'chevron-left': IconRegularChevronLeft,
    'chevron-right': IconRegularChevronRight,
    'undo-alt': IconRegularUndoAlt,
    undo: IconRegularUndo,
    at: IconRegularAt,
    spinner: IconRegularSpinner,
    tractor: IconRegularTractor,
    trailer: IconRegularTrailer,
    minus: IconRegularMinus,
    plus: IconRegularPlus,
    bars: IconRegularBars,
    check: IconRegularCheck,
    'check-square': IconRegularCheckSquare,
    'square-o': IconRegularSquareO,
    circle: IconRegularCircle,
    'check-circle': IconRegularCircleSquare,
    'arrow-left': IconRegularArrowLeft,
    'arrow-up': IconRegularArrowUp,
    'arrow-down': IconRegularArrowDown,
    'trash-alt': IconRegularTrashAlt,
    pen: IconRegularPen,
    edit: IconRegularEdit,
    'info-circle': IconRegularInfoCircle,
    home: IconRegularHome,
    truck: IconRegularTruck,
    'truck-loading': IconRegularTruckLoading,
    'truck-fast': IconRegularTruckFast,
    'clipboard-check': IconRegularClipboardCheck,
    'box-open': IconRegularBoxOpen,
    forklift: IconRegularForklift,
    ruler: IconRegularRuler,
    trash: IconRegularTrash,
    'thums-up': IconRegularThumbsUp,
    'thums-down': IconRegularThumbsDown,
    copy: IconRegularCopy,
  },
  fal: {
    times: IconLigthTimes,
    star: IconLigthStar,
    envelope: IconLightEnvelope,
    phone: IconLightPhone,
    spinner: IconLightSpinner,
    tractor: IconLightTractor,
    plus: IconLightPlus,
    clock: IconLightClock,
    check: IconLightCheck,
    'chevron-up': IconLightChevronUp,
    'chevron-down': IconLightChevronDown,
    'shipping-timed': IconLightShippingTimed,
    'map-marker': IconLightMapMarker,
    'exclamation-circle': IconLightExclamationCircle,
    'share-alt': IconLightShareAlt,
    frown: IconLightFrown,
    undo: IconLightUndo,
    tag: IconLightTag,
    raindrops: IconLightRaindrops,
    search: IconLightSearch,
    'chevron-left': IconLightChevronLeft,
    'hand-holding-box': IconLightHandHoldingBox,
    'circle-location-arrow': IconLightLocationCircle,
    'truck-clock': IconLightTruckClock,
  },
  fab: {
    facebook: IconBrandFacebook,
    twitter: IconBrandTwitter,
    pinterest: IconBrandPinterest,
    instagram: IconBrandInstagram,
    whatsapp: IconBrandWhatsapp,
    google: IconBrandGoogle,
    amazon: IconBrandAmazon,
  },
  clr: {
    facebook: IconColoredFacebook,
    google: IconColoredGoogle,
    amazon: IconColoredAmazon,
    instagram: IconColoredInstagram,
    tiktok: IconColoredTiktok,
  },
  pym: {
    amazon: IconPaymentAmazon,
    'apple-pay': IconPaymentApplePay,
    cirrus: IconPaymentCirrus,
    diners: IconPaymentDiners,
    discover: IconPaymentDiscover,
    'google-pay': IconPaymentGooglePay,
    jcb: IconPaymentJCB,
    maestro: IconPaymentMaestro,
    'master-card': IconPaymentMastercard,
    paypal: IconPaymentPayPal,
    visa: IconPaymentVisa,
    'visa-electron': IconPaymentVisaElectron,
  },
}
