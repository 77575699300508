import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a4d373c4 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _23f1716d = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _30c598e5 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _5fff4486 = () => interopDefault(import('../pages/collections/index.vue' /* webpackChunkName: "pages/collections/index" */))
const _72a8f5ae = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _3bcbd2f1 = () => interopDefault(import('../pages/account/addresses/index.vue' /* webpackChunkName: "pages/account/addresses/index" */))
const _b54417ba = () => interopDefault(import('../pages/account/change-email.vue' /* webpackChunkName: "pages/account/change-email" */))
const _1f854964 = () => interopDefault(import('../pages/account/change-password.vue' /* webpackChunkName: "pages/account/change-password" */))
const _7433eb6c = () => interopDefault(import('../pages/account/edit-profile.vue' /* webpackChunkName: "pages/account/edit-profile" */))
const _24cdd6f8 = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _6698412c = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _11e3690d = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _6596b16e = () => interopDefault(import('../pages/auth/validation.vue' /* webpackChunkName: "pages/auth/validation" */))
const _5061ca78 = () => interopDefault(import('../pages/blogs/ytc-life/index.vue' /* webpackChunkName: "pages/blogs/ytc-life/index" */))
const _97136e6a = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _30f03654 = () => interopDefault(import('../pages/checkout/guest/index.vue' /* webpackChunkName: "pages/checkout/guest/index" */))
const _6aa59c05 = () => interopDefault(import('../pages/checkout/info.vue' /* webpackChunkName: "pages/checkout/info" */))
const _bdb1f67c = () => interopDefault(import('../pages/checkout/payment/index.vue' /* webpackChunkName: "pages/checkout/payment/index" */))
const _3f611a8b = () => interopDefault(import('../pages/pages/about-us.vue' /* webpackChunkName: "pages/pages/about-us" */))
const _2354a550 = () => interopDefault(import('../pages/pages/contact-us.vue' /* webpackChunkName: "pages/pages/contact-us" */))
const _287d4f1d = () => interopDefault(import('../pages/pages/cookies-policy.vue' /* webpackChunkName: "pages/pages/cookies-policy" */))
const _95fd15ec = () => interopDefault(import('../pages/pages/delivery-info.vue' /* webpackChunkName: "pages/pages/delivery-info" */))
const _4066610e = () => interopDefault(import('../pages/pages/faq.vue' /* webpackChunkName: "pages/pages/faq" */))
const _58c44872 = () => interopDefault(import('../pages/pages/help-center/index.vue' /* webpackChunkName: "pages/pages/help-center/index" */))
const _7e5216dc = () => interopDefault(import('../pages/pages/legal.vue' /* webpackChunkName: "pages/pages/legal" */))
const _29c96ef8 = () => interopDefault(import('../pages/pages/privacy-policy.vue' /* webpackChunkName: "pages/pages/privacy-policy" */))
const _26d5a058 = () => interopDefault(import('../pages/pages/returns-refunds.vue' /* webpackChunkName: "pages/pages/returns-refunds" */))
const _0b3183dd = () => interopDefault(import('../pages/pages/shows-events.vue' /* webpackChunkName: "pages/pages/shows-events" */))
const _6ff4a7d0 = () => interopDefault(import('../pages/pages/sitemap.vue' /* webpackChunkName: "pages/pages/sitemap" */))
const _6b66e04d = () => interopDefault(import('../pages/pages/store-locator.vue' /* webpackChunkName: "pages/pages/store-locator" */))
const _b391312a = () => interopDefault(import('../pages/pages/terms-conditions.vue' /* webpackChunkName: "pages/pages/terms-conditions" */))
const _14903e1f = () => interopDefault(import('../pages/wishlists/create.vue' /* webpackChunkName: "pages/wishlists/create" */))
const _2dcc9b02 = () => interopDefault(import('../pages/account/addresses/new.vue' /* webpackChunkName: "pages/account/addresses/new" */))
const _4f100778 = () => interopDefault(import('../pages/auth/email/verify/index.vue' /* webpackChunkName: "pages/auth/email/verify/index" */))
const _391238d3 = () => interopDefault(import('../pages/auth/password/reset/index.vue' /* webpackChunkName: "pages/auth/password/reset/index" */))
const _9acfb9e4 = () => interopDefault(import('../pages/pages/help-center/missing-items.vue' /* webpackChunkName: "pages/pages/help-center/missing-items" */))
const _19d54b9c = () => interopDefault(import('../pages/pages/help-center/missing-order.vue' /* webpackChunkName: "pages/pages/help-center/missing-order" */))
const _2d09796a = () => interopDefault(import('../pages/pages/help-center/order-damaged.vue' /* webpackChunkName: "pages/pages/help-center/order-damaged" */))
const _15fa016a = () => interopDefault(import('../pages/account/order/cancel/_id.vue' /* webpackChunkName: "pages/account/order/cancel/_id" */))
const _1c16a9ea = () => interopDefault(import('../pages/account/social-accounts/unlink/_id.vue' /* webpackChunkName: "pages/account/social-accounts/unlink/_id" */))
const _553844ce = () => interopDefault(import('../pages/account/addresses/_id.vue' /* webpackChunkName: "pages/account/addresses/_id" */))
const _c4af1176 = () => interopDefault(import('../pages/account/order/_id.vue' /* webpackChunkName: "pages/account/order/_id" */))
const _15290f96 = () => interopDefault(import('../pages/auth/callback/_slug.vue' /* webpackChunkName: "pages/auth/callback/_slug" */))
const _53cf0d08 = () => interopDefault(import('../pages/blogs/ytc-life/_slug.vue' /* webpackChunkName: "pages/blogs/ytc-life/_slug" */))
const _47095c47 = () => interopDefault(import('../pages/checkout/callback/_id.vue' /* webpackChunkName: "pages/checkout/callback/_id" */))
const _70b110a7 = () => interopDefault(import('../pages/checkout/success/_id.vue' /* webpackChunkName: "pages/checkout/success/_id" */))
const _03914341 = () => interopDefault(import('../pages/collections/_slug/index.vue' /* webpackChunkName: "pages/collections/_slug/index" */))
const _60faf1a6 = () => interopDefault(import('../pages/pages/_slug.vue' /* webpackChunkName: "pages/pages/_slug" */))
const _7616383e = () => interopDefault(import('../pages/products/_slug.vue' /* webpackChunkName: "pages/products/_slug" */))
const _065b1e22 = () => interopDefault(import('../pages/wishlists/_id/edit.vue' /* webpackChunkName: "pages/wishlists/_id/edit" */))
const _da803dd2 = () => interopDefault(import('../pages/collections/_slug/products/_prodslug.vue' /* webpackChunkName: "pages/collections/_slug/products/_prodslug" */))
const _0509bea2 = () => interopDefault(import('../pages/wishlists/_id/_slug.vue' /* webpackChunkName: "pages/wishlists/_id/_slug" */))
const _f334fd00 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _f6a23f90 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))
const _2df0c58d = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _a4d373c4,
    name: "account___en"
  }, {
    path: "/auth",
    component: _23f1716d,
    name: "auth___en"
  }, {
    path: "/cart",
    component: _30c598e5,
    name: "cart___en"
  }, {
    path: "/collections",
    component: _5fff4486,
    name: "collections___en"
  }, {
    path: "/products",
    component: _72a8f5ae,
    name: "products___en"
  }, {
    path: "/account/addresses",
    component: _3bcbd2f1,
    name: "account-addresses___en"
  }, {
    path: "/account/change-email",
    component: _b54417ba,
    name: "account-change-email___en"
  }, {
    path: "/account/change-password",
    component: _1f854964,
    name: "account-change-password___en"
  }, {
    path: "/account/edit-profile",
    component: _7433eb6c,
    name: "account-edit-profile___en"
  }, {
    path: "/auth/login",
    component: _24cdd6f8,
    name: "auth-login___en"
  }, {
    path: "/auth/reset",
    component: _6698412c,
    name: "auth-reset___en"
  }, {
    path: "/auth/signup",
    component: _11e3690d,
    name: "auth-signup___en"
  }, {
    path: "/auth/validation",
    component: _6596b16e,
    name: "auth-validation___en"
  }, {
    path: "/blogs/ytc-life",
    component: _5061ca78,
    name: "blogs-ytc-life___en"
  }, {
    path: "/checkout/delivery",
    component: _97136e6a,
    name: "checkout-delivery___en"
  }, {
    path: "/checkout/guest",
    component: _30f03654,
    name: "checkout-guest___en"
  }, {
    path: "/checkout/info",
    component: _6aa59c05,
    name: "checkout-info___en"
  }, {
    path: "/checkout/payment",
    component: _bdb1f67c,
    name: "checkout-payment___en"
  }, {
    path: "/pages/about-us",
    component: _3f611a8b,
    name: "pages-about-us___en"
  }, {
    path: "/pages/contact-us",
    component: _2354a550,
    name: "pages-contact-us___en"
  }, {
    path: "/pages/cookies-policy",
    component: _287d4f1d,
    name: "pages-cookies-policy___en"
  }, {
    path: "/pages/delivery-info",
    component: _95fd15ec,
    name: "pages-delivery-info___en"
  }, {
    path: "/pages/faq",
    component: _4066610e,
    name: "pages-faq___en"
  }, {
    path: "/pages/help-center",
    component: _58c44872,
    name: "pages-help-center___en"
  }, {
    path: "/pages/legal",
    component: _7e5216dc,
    name: "pages-legal___en"
  }, {
    path: "/pages/privacy-policy",
    component: _29c96ef8,
    name: "pages-privacy-policy___en"
  }, {
    path: "/pages/returns-refunds",
    component: _26d5a058,
    name: "pages-returns-refunds___en"
  }, {
    path: "/pages/shows-events",
    component: _0b3183dd,
    name: "pages-shows-events___en"
  }, {
    path: "/pages/sitemap",
    component: _6ff4a7d0,
    name: "pages-sitemap___en"
  }, {
    path: "/pages/store-locator",
    component: _6b66e04d,
    name: "pages-store-locator___en"
  }, {
    path: "/pages/terms-conditions",
    component: _b391312a,
    name: "pages-terms-conditions___en"
  }, {
    path: "/wishlists/create",
    component: _14903e1f,
    name: "wishlists-create___en"
  }, {
    path: "/account/addresses/new",
    component: _2dcc9b02,
    name: "account-addresses-new___en"
  }, {
    path: "/auth/email/verify",
    component: _4f100778,
    name: "auth-email-verify___en"
  }, {
    path: "/auth/password/reset",
    component: _391238d3,
    name: "auth-password-reset___en"
  }, {
    path: "/pages/help-center/missing-items",
    component: _9acfb9e4,
    name: "pages-help-center-missing-items___en"
  }, {
    path: "/pages/help-center/missing-order",
    component: _19d54b9c,
    name: "pages-help-center-missing-order___en"
  }, {
    path: "/pages/help-center/order-damaged",
    component: _2d09796a,
    name: "pages-help-center-order-damaged___en"
  }, {
    path: "/account/order/cancel/:id?",
    component: _15fa016a,
    name: "account-order-cancel-id___en"
  }, {
    path: "/account/social-accounts/unlink/:id?",
    component: _1c16a9ea,
    name: "account-social-accounts-unlink-id___en"
  }, {
    path: "/account/addresses/:id",
    component: _553844ce,
    name: "account-addresses-id___en"
  }, {
    path: "/account/order/:id?",
    component: _c4af1176,
    name: "account-order-id___en"
  }, {
    path: "/auth/callback/:slug?",
    component: _15290f96,
    name: "auth-callback-slug___en"
  }, {
    path: "/blogs/ytc-life/:slug?",
    component: _53cf0d08,
    name: "blogs-ytc-life-slug___en"
  }, {
    path: "/checkout/callback/:id?",
    component: _47095c47,
    name: "checkout-callback-id___en"
  }, {
    path: "/checkout/success/:id?",
    component: _70b110a7,
    name: "checkout-success-id___en"
  }, {
    path: "/collections/:slug",
    component: _03914341,
    name: "collections-slug___en"
  }, {
    path: "/pages/:slug?",
    component: _60faf1a6,
    name: "pages-slug___en"
  }, {
    path: "/products/:slug",
    component: _7616383e,
    name: "products-slug___en"
  }, {
    path: "/wishlists/:id?/edit",
    component: _065b1e22,
    name: "wishlists-id-edit___en"
  }, {
    path: "/collections/:slug/products/:prodslug?",
    component: _da803dd2,
    name: "collections-slug-products-prodslug___en"
  }, {
    path: "/wishlists/:id?/:slug?",
    component: _0509bea2,
    name: "wishlists-id-slug___en"
  }, {
    path: "/",
    component: _f334fd00,
    name: "index___en"
  }, {
    path: "/:slug",
    component: _f6a23f90,
    name: "slug___en"
  }, {
    path: "/*",
    component: _2df0c58d,
    name: "all___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
