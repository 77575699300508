
import SignupForm from '@/containers/account/forms/SignupForm.vue'
export default {
  components: {
    SignupForm,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    mode: {
      type: String,
      default: 'normal',
      validator: (value) => ['normal', 'checkout', 'account'].includes(value),
    },
  },
  data() {
    return {
      modal: {
        signup: true,
      },
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
  },
}
