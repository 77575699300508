
import { mapActions } from 'vuex'
import GuestForm from '@/containers/account/forms/GuestForm'
export default {
  components: { GuestForm },
  props: {
    email: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      data: null,
    }
  },
  mounted() {
    this.createOrder()
  },

  methods: {
    ...mapActions('checkout', ['getCheckout']),
    createOrder() {
      // set loading
      this.loading = true

      const handleSuccess = (checkout) => {
        this.loading = false
        this.data = Object.assign({}, checkout)
      }

      const handleError = (error) => {
        this.loading = false

        console.warn(
          (error.response && error.response.message) ||
            this.$t('cart.cannotFetchCheckout')
        )
      }

      const createNew = () => {
        this.$store
          .dispatch('checkout/guestCheckout')
          .then(handleSuccess)
          .catch(handleError)
      }

      this.getCheckout()
        .then((checkout) => {
          if (checkout) {
            handleSuccess(checkout)
          } else {
            createNew()
          }
        })
        .catch(createNew)
    },
    close() {
      this.$emit('close')
    },
  },
}
