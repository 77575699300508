import https from 'https'
const onErrorHandler = (vm) => (error) => {
  if (error instanceof Error) {
    try {
      console.error(`Failed: ${error.config.baseURL}${error.config.url}`)
    } catch (e) {
      //
    }

    return
  }

  const ignoreEndpointsForNotification = [
    'applyDiscount',
    'removeDiscount',
    'checkout',
    'payment/purchase',
    'cart',
  ]

  const url =
    error.response && error.response.config && error.response.config.url
      ? error.response.config.url
      : error.config.url

  const shouldNotify = !ignoreEndpointsForNotification.find((x) => url.match(x))
  const ignoreEndpoints = ['search/suggestion']
  const shouldAct =
    (error.config && error.config.notifications) ||
    !ignoreEndpoints.find((x) => url.match(x))

  if (shouldAct && error && error.response && error.response.status) {
    switch (error.response.status) {
      case 401:
        vm.$auth.logout()
        vm.store.dispatch('checkout/clear')

        if (!url.match('auth/signIn')) {
          vm.store.dispatch('notification/addNotification', {
            type: 'error',
            message: 'Your session is invalid.',
          })
        }
        break
      case 404:
        vm.error(404, 'Not found')
        break
      case 429:
        vm.store.dispatch('notification/addNotification', {
          type: 'error',
          message:
            'You had exceeded request limit. Please try again a few seconds later.',
        })
        break
      case 403:
        // console.log('redirect to access denied')
        break
      case 422:
        // console.log('redirect to access denied')
        break
      case 500:
        if (error.response.data && error.response.data.errorCode === 1001) {
          // Redirect to product page
          if (error.response.data.data && error.response.data.data.slug) {
            vm.redirect(
              error.response.status,
              `/${error.response.data.data.slug}`
            )
          }

          // Show error notifications
          if (error.response.data.notifications) {
            error.response.data.notifications.forEach((m) => {
              vm.store.dispatch('notification/addNotification', m)
            })
          }
        }
        break
      default:
        if (
          shouldNotify &&
          error.response.data &&
          error.response.data.message
        ) {
          vm.store.dispatch('notification/addNotification', {
            type: 'error',
            message: error.response.data.message,
          })
        }
        break
    }

    if (
      shouldNotify &&
      error.response.data &&
      error.response.data.notifications
    ) {
      error.response.data.notifications.forEach((m) => {
        vm.store.dispatch('notification/addNotification', m)
      })
    }
  }
}

/* eslint-disable */
const onSuccessHandler = (vm) => (response) => {
  if (response && response.data && response.data.notifications) {
    response.data.notifications.forEach((m) => {
      vm.store.dispatch('notification/addNotification', m)
    })
  }
}

export default function (vm) {
  vm.$axios.defaults.baseURL = process.server
    ? process.env.serverApi
    : process.env.clientApi

  /**
   * Disable only in development mode
   */
  if (process.env.NODE_ENV === 'local') {
    const httpsAgent = new https.Agent({
      rejectUnauthorized: false,
    })

    vm.$axios.defaults.httpsAgent = httpsAgent
    // eslint-disable-next-line no-console
    console.log(process.env.NODE_ENV, `RejectUnauthorized is disabled.`)
  }

  vm.$axios.onRequest((config) => {
    if (config.baseURL === vm.$axios.defaults.baseURL) {
      const cookieCountry = vm.$cookies.get('country') || vm.i18n.locale

      const selectedCountry = vm.i18n.locales.filter((locale) => {
        return locale.code === cookieCountry
      })
      const reqeustCode = selectedCountry[0].reqeustCode

      config.headers['X-Language-Code'] = reqeustCode
      config.headers['X-Currency-Code'] = selectedCountry[0].currencyCode
      config.headers['X-Session-Id'] = vm.store.state.session.sessionId
      config.headers['X-ShippingCountryId'] = selectedCountry[0].id
    }
  })

  vm.$axios.onResponse((response) => {
    onSuccessHandler(vm)
  })
  vm.$axios.onResponseError(onErrorHandler(vm))
}
