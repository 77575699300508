
import { mapActions } from 'vuex'
import facebook from '@/assets/img/social/facebook.webp'
import google from '@/assets/img/social/google.webp'
import amazon from '@/assets/img/social/amazon.webp'

const logins = [
  { image: google, name: 'Google' },
  // { image: facebook, name: 'Facebook' },
  { image: amazon, name: 'Amazon' },
]

export default {
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    returnUrl: {
      type: String,
      default: null,
    },
    itemClass: {
      type: String,
      default: 'mb-4',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logins,
      isLoading: false,
    }
  },
  watch: {
    returnUrl: {
      handler() {
        this.setReturnUrl()
      },
    },
  },
  mounted() {
    this.setReturnUrl()
  },
  methods: {
    ...mapActions('notification', ['addNotification']),
    getRedirect(item) {
      this.isLoading = true

      this.$axios
        .get(`auth/social/${item.name.toLowerCase()}/redirect`)
        .then((response) => {
          window.location = response.data.data
        })
        .catch(() => {
          this.addNotification({
            message: this.$t('cannotGetRedirectLink'),
            type: 'error',
            duration: 15000,
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    setReturnUrl() {
      localStorage.setItem(
        'socialLogin.returnUrl',
        JSON.stringify(this.returnUrl)
      )
    },
  },
}
