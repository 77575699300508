
import { parseErrors } from '@/plugins/utilities'

const emptyData = { email: '' }
export default {
  data() {
    return {
      tempEmail: '',
      data: { ...emptyData },
      error: null,
      loading: false,
      done: false,
      returnUrl: '/',
      backLink: '/auth',
    }
  },
  mounted() {
    const { error, data, returnUrl, backRoute, backUrl } = this.$route.params
    this.returnUrl = returnUrl
    this.backLink = backRoute
      ? { name: backRoute, returnUrl }
      : backUrl || '/auth'
    this.error = error
    this.data = { ...this.data, ...data }
  },
  methods: {
    submit() {
      this.loading = true
      this.errors = null
      const { data } = this
      this.$axios
        .$post('/auth/password/reset/email', { ...data })
        .then(() => {
          this.tempEmail = this.data.email
          this.data = { ...emptyData }
          this.done = true
        })
        .catch((error) => {
          this.error =
            error.response.data.errors &&
            parseErrors(error.response.data.errors)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
