
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'white',
    },
    thinBorder: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    iconset: {
      type: String,
      default: undefined,
    },
    iconSpin: {
      type: Boolean,
      default: false,
    },
    iconRight: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
    },
    iconClass: {
      type: [String, Array, Object],
      default: null,
    },
    to: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
  },
  computed: {
    label() {
      return (
        this.$slots.default &&
        this.$slots.default[0] &&
        this.$slots.default[0].text &&
        this.$slots.default[0].text.trim()
      )
    },
  },
  methods: {
    click($event) {
      if (this.to) {
        $event.preventDefault()
        this.$router.push(this.to)
      }
    },
  },
}
