
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
      validator: (value) =>
        ['small', 'normal', 'large', 'xlarge'].includes(value),
    },
  },
  methods: {
    open() {
      this.$emit('input', true)
      this.$emit('change', true)
    },
    close() {
      this.$emit('input', false)
      this.$emit('change', false)
    },
  },
}
