
export default {
  props: {
    checkoutData: {
      type: Object,
      default: () => ({}),
    },
    email: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      data: {
        email: this.email,
      },
      error: null,
      loading: false,
    }
  },

  methods: {
    validateEmail(value) {
      // if the field is empty
      if (!value) {
        return 'The Email field is required.'
      }
      // if the field is not a valid email
      const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
      if (!regex.test(value)) {
        return 'The Email must be a valid email address.'
      }
      // All is good
      return true
    },
    submit() {
      this.loading = true
      const validEmail = this.validateEmail(this.data.email)
      if (validEmail === true) {
        const data = {
          session_id: this.checkoutData?.order?.session_id,
          email: this.data.email,
        }

        this.$axios
          .post('checkout/guest/email', data)
          .then(() => {
            this.$router.push(
              this.localePath({
                path: '/checkout/info',
                query: { email: this.data.email },
              })
            )
            this.$emit('close')
          })
          .catch((error) => {
            this.loading = false
            console.log(error)
          })
      } else {
        this.error = validEmail
        this.loading = false
      }
    },
  },
}
