
import SocialLogin from '@/containers/account/SocialLogin'
import LoginForm from '@/containers/account/forms/LoginForm'

export default {
  components: {
    SocialLogin,
    LoginForm,
  },
  props: {
    guest: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'normal',
      validator: (value) => ['normal', 'checkout', 'account'].includes(value),
    },
  },
  data() {
    return {
      login: {
        step: 1, // 1:login step 1, 2:login step 2, 3:signup
      },
      data: { email: '' },
      modal: {
        login: true,
        signup: false,
        guest: false,
      },
    }
  },
  computed: {
    getTitle() {
      let title = 'Login for Checkout'
      if (this.login.step === 2) {
        title = 'Welcome back!'
      }
      return title
    },
    getDescription() {
      let description = ''
      if (this.login.step === 2) {
        description = 'Login with' + this.data.email
      }
      return description
    },
  },
  methods: {
    openGuestModal(email) {
      this.$emit('close', true)
      this.$userGuest({ email })
    },
    openResetModal() {
      this.$emit('close', true)
      this.$userReset()
    },
    openSignupModal(email) {
      this.$emit('close', true)
      this.$userSignup({ email, mode: 'checkout' })
    },
    handleUser(data) {
      const { exists, email } = data
      if (exists) {
        this.data.email = email
        this.login.step = 2
      } else {
        this.openSignupModal(email)
      }
    },
    close() {
      this.$emit('close', true)
    },
  },
}
