import * as Sentry from '@sentry/vue'
import Vue from 'vue'

export default ({ app }, inject) => {
  if (process.env.SENTRY_DSN) {
    const isProduction =
      process.env.NODE_ENV === 'production' ||
      process.env.ENVIRONMENT === 'production'

    try {
      Sentry.init({
        Vue,

        // the runtime version configurations
        dsn: process.env.SENTRY_DSN,
        release: process.env.VERSION || process.env.npm_package_version,
        environment: process.env.ENVIRONMENT || 'development',

        integrations: [
          ...(isProduction
            ? []
            : [
                // If you use a bundle with tracing enabled, add the BrowserTracing integration
                Sentry.browserTracingIntegration(),
                // If you use a bundle with session replay enabled, add the Replay integration
                Sentry.replayIntegration({
                  // Additional SDK configuration goes in here, for example:
                  maskAllText: false,
                  blockAllMedia: true,
                }),
              ]),
        ],

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: isProduction ? 0.1 : 1.0,

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          'yorkshiretrading.com',
          'api.yorkshiretrading.com',
          'staging.yorkshiretrading.com',
          'api-staging.yorkshiretrading.com',
        ],

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: isProduction ? 0.1 : 1.0,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
      })

      inject('sentry', Sentry)
    } catch (e) {
      console.error('Sentry initialization failed:', e)
    }
  }
}
