import Vue from 'vue'

Vue.filter('money', (str) => {
  if (str) {
    let fractionDigits = 0
    if (
      Number.parseFloat(str) < Number.parseInt(str) ||
      Number.parseFloat(str) > Number.parseInt(str)
    ) {
      fractionDigits = 2
    }

    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits,
      currency: 'GBP',
    }).format(str)
  } else {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(str)
  }
})
