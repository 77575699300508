/**
 * Facebook Purchase
 * @param {Object} fb Facebook instance
 * @param {Object} param1 { order, orderProducts }
 */
export const purchaseFacebook = (fb, { order, orderProducts }) => {
  if (!fb) {
    return
  }

  fb.track(
    'Purchase',
    // begin parameter object data
    {
      currency: order.currency || 'GBP',
      value: order.total,
    }
    // end parameter object data
  )
}

/**
 * Facebook Purchase
 * @param {Object} fb Facebook instance
 * @param {Object} param1 { order, orderProducts }
 */
export const addToCart = (fb, addedItem) => {
  if (!fb) {
    return
  }

  fb.track(
    'AddToCart',
    // begin parameter object data
    {
      content_name: addedItem.product.title,
      content_ids: [addedItem.product.product_variant_id],
      content_type: 'product',
      content_category: addedItem.metaCategory,
      value: addedItem.price,
      currency: addedItem.currency || 'GBP',
    }
    // end parameter object data
  )
}

/**
 * Facebook Purchase
 * @param {Object} fb Facebook instance
 * @param {Object} param1 { order, orderProducts }
 */
export const viewContent = (fb, product) => {
  if (!fb) {
    return
  }

  fb.track(
    'ViewContent',
    // begin parameter object data
    {
      content_name: product.title,
      content_ids: [product.product_variant_id],
      content_type: 'product',
      value: product.price,
      currency: product.currency || 'GBP',
    }
    // end parameter object data
  )
}

/**
 * Facebook Purchase
 * @param {Object} fb Facebook instance
 * @param {Object} param1 { order, orderProducts }
 */
export const addToWishlist = (fb, product, productVariant) => {
  if (!fb) {
    return
  }

  fb.track(
    'AddToWishlist',
    // begin parameter object data
    {
      content_name: product.title,
      content_ids: [productVariant.id],
      content_type: 'product',
      content_category: product.metaCategory,
      value: product.price,
      currency: product.currency || 'GBP',
    }
    // end parameter object data
  )
}

/**
 * Facebook initiateCheckout
 * @param {Object} fb Facebook instance
 */
export const initiateCheckout = (fb, cart) => {
  if (!fb) {
    return
  }

  fb.track('InitiateCheckout', {
    content_name: 'Shopping Cart',
    content_ids: cart.items.map((item) => item.productVariant.id), // variant id lerini gonderebiliriz sepetteki
    content_type: cart.items.length > 1 ? 'product_group' : 'product', // type a cart yazilir mi bilmiyorum ama yazalim gorelim
    content_category: cart.metaCategory,
    value: cart.total, // cart total
    currency:
      (cart.items && cart.items.length && cart.items[0].currency) || 'GBP', // currency
  })
}

/**
 * Facebook completeRegistration
 * @param {Object} fb Facebook instance
 */
export const completeRegistration = (fb, signUpStatus) => {
  if (!fb) {
    return
  }

  fb.track('CompleteRegistration', {
    content_name: 'Sign Up',
    status: signUpStatus,
  })
}

/**
 * Facebook AddPaymentInfo
 * @param {Object} fb Facebook instance
 */
export const addPaymentInfo = (fb, cart) => {
  if (!fb) {
    return
  }

  fb.track('AddPaymentInfo', {
    content_name: 'Shopping Cart',
    content_ids: cart.items.map((item) => item.productVariant.id),
    content_type: cart.items.length > 1 ? 'product_group' : 'product',
    content_category: cart.metaCategory,
    value: cart.total,
    currency:
      (cart.items && cart.items.length && cart.items[0].currency) || 'GBP',
  })
}
