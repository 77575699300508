
import { parseErrors } from '@/plugins/utilities'
import { loginSignInGTM } from '~/plugins/gtm-helper'
const emptyData = { email: '', password: '' }
const tokenStorageName = 'auth.validation_token'
export default {
  props: {
    mode: {
      type: String,
      default: 'normal',
      validator: (value) => ['normal', 'checkout', 'account'].includes(value),
    },
    step: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      data: {
        email: '',
        password: '',
        remember: false,
      },
      error: null,
      loading: false,
    }
  },
  methods: {
    async isEmailExist() {
      this.loading = true
      return await this.$axios
        .post('/auth/emailExists', { email: this.data.email })
        .then((response) => {
          return response.data.exists
        })
        .finally(() => {
          this.loading = false
        })
    },
    async handleUser() {
      const emailExists = await this.isEmailExist()
      const data = {
        email: this.data.email,
        exists: emailExists,
      }

      this.$emit('handle', data)
    },
    submit() {
      this.loading = true
      const { data } = this
      const email = data.email.toLowerCase()
      const password = data.password

      this.$auth
        .loginWith('local', {
          data: {
            email,
            password,
          },
        })
        .then(() => {
          this.data = { ...emptyData }
          // clear checkout
          this.$store.dispatch('checkout/clear')

          // GTM successful login
          loginSignInGTM('login', this.$auth.user.id)

          if (this.mode === 'checkout') {
            this.$router.push(this.localePath('/checkout/info'))
          } else if (this.mode === 'account') {
            this.$router.push(this.localePath('/account'))
          } else {
            this.$router.push(this.localePath('/'))
          }
          this.$emit('close')
        })
        .catch((e) => {
          if (e.response.data.status === 'require_verification') {
            localStorage.setItem(tokenStorageName, e.response.data.token)
            this.$router.push(
              this.localePath({
                name: 'auth-validation',
                params: {
                  token: e.response.data.token,
                  returnUrl: '/checkout/info',
                },
              })
            )
          } else {
            this.error =
              e.response.data.errors && parseErrors(e.response.data.errors)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
