const defaultItem = (item) => ({
  message: 'Message',
  duration: 3000,
  dismissable: true,
  type: 'default', // default, success, danger, custom
  ...item,
  id: Math.random().toString(36).substr(2, 8),
})

export const state = () => ({
  items: [],
})

export const mutations = {
  add(state, item) {
    state.items = [...state.items, defaultItem(item)]
  },

  set(state, item) {
    state.item = item
  },

  update(state, item) {
    const items = [...state.items]
    const found = items.findIndex((x) => x.id === item.id)

    if (found < 0) {
      return false
    }

    items[found] = {
      ...state.items[found],
      ...item,
    }

    state.items = [...items]
  },

  remove(state, item) {
    const { items } = state

    const found = items.findIndex((x) => x.id === item.id)

    if (found < 0) {
      return false
    }

    items.splice(found, 1)
    state.items = [...items]
  },

  // Loads only when array is empty
  load(state, items) {
    state.items = [...items]
  },

  clear(state) {
    state.items = []
  },
}

export const getters = {
  list(state) {
    return state.items
  },
  reversed(state) {
    return [...state.items].reverse()
  },
}

export const actions = {
  addNotification({ commit }, item) {
    if (typeof item === 'string') {
      commit('add', { message: item })
    } else {
      commit('add', { ...item })
    }
  },

  catchNotification({ commit }, error, defaultMessage = null, duration = 3000) {
    let message = defaultMessage || 'Ooppps! Something wrong.'
    if (error && error.response) {
      const { data: response } = error.response
      let serverError = null

      if (response.errors) {
        for (const key of Object.keys(response.errors)) {
          const errors = response.errors[key]
          if (!serverError && errors) {
            serverError = errors[0]
          }
        }
      }

      if (!serverError && response.message) {
        serverError = response.message
      }

      message = serverError || message
    }

    commit('add', {
      message,
      type: 'error',
      duration,
    })
  },

  dismissNotification({ commit }, item) {
    commit('remove', { ...item })
  },

  fetchAllNotifications({ commit }) {
    commit('load')
  },

  fetchUnreadNotifications({ commit }) {
    commit('load')
  },

  clearNotifications({ commit }) {
    commit('clear')
  },
}
