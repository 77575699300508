import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
dayjs.extend(relativeTime)
dayjs.extend(utc)

/* eslint-disable import/first */
export const productMap = (product) => {
  return {
    ...product,
    options: productOptionsMap(product.groupedProductOptions),
    media: product.media || (product.gallery.length && product.gallery[0]),
    secondMedia:
      product.secondMedia || (product.gallery.length > 1 && product.gallery[1]),
  }
}

export const productOptionsMap = (groupedProductOptions) => {
  return groupedProductOptions
    .map((option) => ({
      [option.slug]: option.values.map((value) => ({
        // DATA: value, // remove it after debugging.
        value: value.product_option_id,
        slug: value.slug,
        title: value.name,
        label: value.color || value.media || value.name,
        is_sold_out: Object.prototype.hasOwnProperty.call(value, 'is_sold_out')
          ? value.is_sold_out
          : false,
        type: value.color
          ? 'color'
          : value.media
          ? 'media'
          : value.name
          ? value.name.length > 3
            ? 'long'
            : 'name'
          : 'name',
      })),
    }))
    .reduce((p, c) => ({ ...p, ...c }), {})
}

export const cartRemapper = (x) => {
  return {
    ...x,
    product: {
      ...x.product,
      ...x.productVariant,
      id: x.product.id,
      product_variant_id: x.productVariant.id,
      currency_price: x.currency_price,
      currency_variant_price: x.productVariant.currency_price,
      currency_undiscounted_price: x.currency_undiscounted_price
        ? x.currency_undiscounted_price
        : x.product.currency_undiscounted_price,
      media: x.productVariant.media || x.product.media,
      secondaryMedia:
        x.productVariant.secondaryMedia || x.product.secondaryMedia,
      buy_more: x.buy_more,
      free: x.free_product_count,
      isFreeCart: x.isFreeCart,
      variantTitle: x.product.variantTitle,
    },
    quantity: x.quantity,
  }
}

export const cartOrderMap = (x) => {
  return x.has_variants
    ? {
        cart_id: x.cart_id,
        product_variant_id: x.product_variant_id,
        quantity: x.quantity,
      }
    : {
        cart_id: x.cart_id,
        product_id: x.product_id ? x.product_id : x.id,
        quantity: x.quantity,
      }
}

export const cartParams = (product) => {
  const key = product.has_variants ? 'product_variant_id' : 'product_id'
  const id = product.has_variants
    ? product.product_id
      ? product.id
      : product.product_variant_id
    : product.product_id
    ? product.product_id
    : product.id

  return {
    [key]: id,
    quantity: product?.quantity || 1,
  }
}

export const wishlistParams = (product, productVariantId) => {
  /* if (product.id) {
    return {
      product_id: product.id,
      product_variant_id: productVariantId
    }
  }

  if (product.product_id) {
    return {
      product_id: product.product_id,
      product_variant_id: productVariantId
    }
  } */

  return cartParams(product)
}

export const generateSelectionsFromProduct = (product) => {
  return Object.keys(product.options)
    .map((x) => ({
      [x]: (() => {
        const found = product.options[x].find((x) =>
          product.productOptionIds.includes(x.value)
        )

        if (found) {
          return found.value
        }

        return null
      })(),
    }))
    .reduce((p, c) => ({ ...p, ...c }), {})
}

export const parseErrors = (errors) => {
  return Object.keys(errors)
    .map((key) => ({ [key]: errors[key].join('\n') }))
    .reduce((a, b) => ({ ...a, ...b }), {})
}

export const scrollToTop = (element) => () => {
  const s = 2
  const c = element.scrollTop
  if (c > s) {
    window.requestAnimationFrame(scrollToTop(element))
    element.scrollTo(0, c - c / s)
    return
  }
  element.scrollTo(0, 0)
}

/*
 *  Return the value of the key from first object containing the key
 */
export const getNotEmptyValue = (key, ...objects) => {
  const obj = objects.find((x) => x && x[key])
  return obj ? obj[key] : null
}

import { orderStatus } from '@/plugins/enums'
import { locale } from 'core-js'

/**
 * Returns available route for order status
 * @param {Number} status Order type
 */
export const getStatusRoute = (status) => {
  switch (status) {
    case orderStatus.waitingInformation:
      return '/checkout/info'
    case orderStatus.waitingDeliveryInformation:
      return '/checkout/delivery'
    case orderStatus.waitingPayment:
      return '/checkout/payment'
    default:
      return '/'
  }
}

/**
 * Returns if order type is one of the 3 steps in checkout or not
 * @param {Number} status Order type
 */
export const isOrderInProgress = (status) => {
  const inProgressStatuses = [
    orderStatus.waitingInformation,
    orderStatus.waitingDeliveryInformation,
    orderStatus.waitingPayment,
  ]
  if (inProgressStatuses.includes(status)) {
    return true
  }
  return false
}

/**
 * Returns filter object of given params for the Filter component
 * @param {Object} params api params
 */
export const getFilterOfParams = (params) => {
  const mapArrayParam = (array) =>
    array
      .map((x) => ({ [x]: true }))
      .reduce((ac, cur) => ({ ...cur, ...ac }), {})

  const filter = {}

  for (const param in params) {
    if (param === 'min_price' || param === 'max_price') {
      filter[param] = params[param]
    } else {
      filter[param] = mapArrayParam(params[param])
    }
  }

  return filter
}

/**
 * Returns api param object created from value of Filter component
 * @param {Object} filter Filter component value
 */
export const getParamsOfFilter = (filter) => {
  return Object.keys(filter)
    .map((x) => {
      if (x === 'min_price' || x === 'max_price') {
        return { [x]: filter[x] }
      } else {
        return { [x]: Object.keys(filter[x]) }
      }
    })
    .reduce((acc, cur) => ({ ...acc, ...cur }), {})
}

export const getQueryStringOfParams = (params) => {
  return Object.keys(params)
    .map((x) => `${x}=${params[x]}`)
    .join('&')
}

/**
 * @param {string} color - 6 characters long hex color with # appended.
 * @param {number} threshold - threshold value to decide its lightness
 * @return {boolean} Returns true if the color is too light
 */
export const isTooLight = (color, threshold = 250) => {
  const hex = parseInt(color.slice(1), 16)
  const r = hex >>> 16
  // eslint-disable-next-line unicorn/number-literal-case
  const g = (hex & 0x00ff00) >>> 8
  // eslint-disable-next-line unicorn/number-literal-case
  const b = (hex & 0x0000ff) >>> 0

  // See Highly Sensitive Poo equation. http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))
  return hsp > threshold
}

/**
 * @param {object} item - Shopping cart item
 * @return {object} Returns the data layer object of cart item
 */
export const cartProductForDataLayer = (item) => {
  return {
    id: item.product_id,
    name: item.product.title,
    price: item.product.currency_price,
    variant: item.product_variant_id,
    quantity: item.quantity,
  }
}

/**
 * Show error page
 * @param {object} error - Error object
 * @param {function} viewError - context.error function
 */
export const handlePageError = (error, viewError) => {
  viewError({ statusCode: error.response && error.response.status })
}

export const checkRedirectURL = (
  data,
  slugRedirect,
  redirect,
  query,
  env,
  route
) => {
  if (data) {
    const queryArray = []

    for (const p in query) {
      if (Object.prototype.hasOwnProperty.call(query, p)) {
        queryArray.push(
          `${encodeURIComponent(p)}=${encodeURIComponent(query[p])}`
        )
      }
    }

    const queryString = queryArray.length ? `?${queryArray.join('&')}` : ''
    if (data.slug !== undefined) {
      if (data.slug !== route.params.slug) {
        const redirectPath = route.matched[0].path.replace(':slug', data.slug)
        return redirect(`${redirectPath}${queryString}`)
      }
    }

    if (data.redirect_url) {
      const redirectPath =
        data.redirect_url.substr(0, 1) !== '/'
          ? `/${data.redirect_url}`
          : data.redirect_url
      return redirect(301, `${env.CLIENT_APP_URL}${redirectPath}${queryString}`)
    }

    if (slugRedirect) {
      return redirect(301, `${env.CLIENT_APP_URL}/${data.slug}${queryString}`)
    }
  }
}

export const checkRedirectURLFinal = (
  data,
  error,
  redirect,
  query,
  env,
  route
) => {
  if (data) {
    const queryArray = []

    for (const p in query) {
      if (Object.prototype.hasOwnProperty.call(query, p)) {
        queryArray.push(
          `${encodeURIComponent(p)}=${encodeURIComponent(query[p])}`
        )
      }
    }

    const queryString = queryArray.length ? `?${queryArray.join('&')}` : ''
    if (data.slug !== undefined) {
      if (data.slug !== route.params.slug) {
        const redirectPath = route.matched[0].path.replace(':slug', data.slug)
        return redirect(`${redirectPath}${queryString}`)
      }
    }

    if (data.redirect_url) {
      const redirectPath =
        data.redirect_url.substr(0, 1) !== '/'
          ? `/${data.redirect_url}`
          : data.redirect_url
      return redirect(301, `${env.CLIENT_APP_URL}${redirectPath}${queryString}`)
    }
    error({ statusCode: 404 })
  }
}

/**
 * Create a string to present given address
 * @param {object} address - Address object
 */
export const createAddressLabel = (address) => {
  const {
    zip,
    address_1: address1,
    address_2: address2,
    city,
    province,
  } = address

  if (!zip) return null

  return `${zip} - ${address1}, ${
    address2 ? address2 + ',' : ''
  } ${city}, ${province}`
}

/**
 * Create a title for given address
 * @param {object} address - Address object
 */
export const createAddressTitle = (address) => {
  if (!address) return null
  const { first_name: firstName, last_name: lastName, zip } = address

  if (!zip) return null

  return `${firstName} ${lastName} - ${zip}`
}
/**
 * Create a string to present given address
 * @param {object} storeAddress - Address object
 */
export const createStoreAddressLabel = (storeAddress) => {
  const { address, city, county, postcode } = storeAddress

  if (!postcode) return null

  return `${postcode} - ${address}, ${city}, ${county}`
}
/**
 * Create a title for given address
 * @param {object} address - Address object
 */
export const createStoreAddressTitle = (address) => {
  if (!address) return null
  const { name, postcode } = address

  if (!postcode) return null

  return `${name} - ${postcode}`
}

export const capitalize = (value) => {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export const Trie = (function () {
  class Trie {
    constructor() {
      this.trie = {}
    }

    push(value) {
      let char, i, j, len, obj
      value = value.toString()
      obj = this.trie
      const ref = value.split('')
      const results = []
      for (i = j = 0, len = ref.length; j < len; i = ++j) {
        char = ref[i]
        if (obj[char] == null) {
          if (i === value.length - 1) {
            obj[char] = null
          } else {
            obj[char] = {}
          }
        }
        results.push((obj = obj[char]))
      }
      return results
    }

    find(value) {
      let char, i, j, len, obj
      value = value.toString()
      obj = this.trie
      const ref = value.split('')
      for (i = j = 0, len = ref.length; j < len; i = ++j) {
        char = ref[i]
        if (Object.prototype.hasOwnProperty.call(obj, char)) {
          if (obj[char] === null) {
            return true
          }
        } else {
          return false
        }
        obj = obj[char]
      }
    }
  }

  return Trie
})()

export const CustomRange = (function () {
  class CustomRange {
    constructor(trie1) {
      this.trie = trie1
      if (this.trie.constructor !== Trie) {
        throw new Error('Range constructor requires a Trie parameter')
      }
    }

    match(number) {
      return this.trie.find(number)
    }

    static rangeWithString(ranges) {
      let j, k, len, n, r, range, ref, ref1
      if (typeof ranges !== 'string') {
        throw new TypeError('rangeWithString requires a string parameter')
      }
      ranges = ranges.replace(/ /g, '')
      ranges = ranges.split(',')
      const trie = new Trie()
      for (j = 0, len = ranges.length; j < len; j++) {
        range = ranges[j]
        if ((r = range.match(/^(\d+)-(\d+)$/))) {
          for (
            n = k = ref = r[1], ref1 = r[2];
            ref <= ref1 ? k <= ref1 : k >= ref1;
            n = ref <= ref1 ? ++k : --k
          ) {
            trie.push(n)
          }
        } else if (range.match(/^\d+$/)) {
          trie.push(range)
        } else {
          throw new Error("Invalid range '" + r + "'")
        }
      }
      return new CustomRange(trie)
    }
  }

  return CustomRange
})()

/**
 * Cookies: https://www.quirksmode.org/js/cookies.html
 */
export const setCookie = (name, value, days) => {
  let expires = ''
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toGMTString()
  }
  document.cookie = name + '=' + value + expires + '; path=/'
}

export const getCookie = (name) => {
  const nameEQ = name + '='
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}

export const deleteCookie = (name) => {
  setCookie(name, '', -1)
}

export const isElementInViewport = (el) => {
  const rect = el.getBoundingClientRect()

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  )
}

/**
 * Returns true if Array 1 and Array 2 is equal
 * @param {Array} arr1 Array 1
 * @param {Array} arr2 Array 2
 */
export const isEqual = (arr1, arr2) => {
  if (
    Array.isArray(arr1) &&
    Array.isArray(arr2) &&
    arr1.length === arr2.length
  ) {
    return arr1.every((val, index) => val === arr2[index])
  }
  return false
}

/**
 * Returns true or false according to the email validation
 * @param {String} email
 * @returns Boolean
 */
export const validateEmail = (email) => {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)
}

/**
 * Updates the array's item index
 * @param {} arr
 * @param {*} oldIndex
 * @param {*} newIndex
 * @returns
 */
export const updateItemIndex = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1
    while (k--) {
      arr.push(undefined)
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0])
  return arr
}

/**
 * Create a string to present given address
 * @param {object} i18n - i18n  object
 * @param {object} i18nHead - i18nHead object
 * @param {String} routeQuery - Route query
 * @param {object} slugData - Slug Data object
 */
export const alternateLinks = (i18n, i18nHead, routeQuery, slugData) => {
  const alternates = []
  i18nHead.link.forEach((locale) => {
    // Do not include the query in the hreflang tags.
    locale.href = locale.href.replace(routeQuery, '')
    if (locale.hreflang === 'x-default' || locale.rel === 'canonical') {
      if (locale.hreflang === 'x-default') {
        locale.href = locale.href.replace(slugData.slug, slugData.slug_en)
      }
      alternates.push(locale)
    }
    i18n.locales.forEach((element) => {
      if (locale.hreflang === element.hreflang) {
        if (
          locale.hreflang === 'en-GB' ||
          locale.hreflang === 'en-IE' ||
          locale.hreflang === 'en-US' ||
          locale.hreflang === 'en-DE' ||
          locale.hreflang === 'en-FR' ||
          locale.hreflang === 'en-BE' ||
          locale.hreflang === 'en-ES' ||
          locale.hreflang === 'en-DK' ||
          locale.hreflang === 'en-SE' ||
          locale.hreflang === 'en-CA' ||
          locale.hreflang === 'en-CH' ||
          locale.hreflang === 'en-NL' ||
          locale.hreflang === 'en-IT'
        ) {
          locale.href = locale.href.replace(slugData.slug, slugData.slug_en)
        } else if (locale.hreflang === 'de-DE' || locale.hreflang === 'de-BE') {
          locale.href = locale.href.replace(slugData.slug, slugData.slug_de)
        } else if (locale.hreflang === 'fr-FR' || locale.hreflang === 'fr-BE') {
          locale.href = locale.href.replace(slugData.slug, slugData.slug_fr)
        } else if (locale.hreflang === 'es-ES') {
          locale.href = locale.href.replace(slugData.slug, slugData.slug_es)
        }
        alternates.push(locale)
      }
    })
  })
  return alternates
}

/**
 * Using for static pages price text
 * Price with currency symbol respective language
 * @param price
 */
export const priceWithSymbol = (price, locale) => {
  const currencyCode = locale.currencyCode
  const symbol = locale.symbol
  return currencyCode === 'GBP'
    ? symbol + price
    : currencyCode === 'USD'
    ? symbol + price
    : `${price} ${symbol}`
}

/**
 * Using for themes assets urls
 * @param media
 */
export const assetURL = (media) => {
  if (typeof media !== 'object') {
    return ['local', 'staging'].includes(process.env.environment)
      ? `${process.env.ASSET_URL_LOCAL}${media}`
      : `${process.env.ASSET_URL_PROD}${media}`
  } else if (media.cf_path && media.alternate) {
    return {
      alternate: true,
      cf_path: `${process.env.CF_MEDIA}${media.cf_path}`,
    }
  } else if (media.cf_path) {
    return `${process.env.CF_MEDIA}${media.cf_path}`
  } else {
    return {
      extension: media.extension,
      format: media.format,
      path: ['local', 'staging'].includes(process.env.environment)
        ? `${process.env.ASSET_URL_LOCAL}${media.path}`
        : `${process.env.ASSET_URL_PROD}${media.path}`,
    }
  }
}

/**
 * Prices updates for giving object
 * @param {Array} themeProducts - Object/array of products list that is shown in theme
 * @param {Array} prices - price list of products
 */
export const productsWithPrices = (themeProducts, prices) => {
  if (themeProducts) {
    return themeProducts.map((item) => {
      const founded = prices.find((i) => {
        return i.slug === item.slug
      })
      if (founded) {
        item.price = founded.price
        item.undiscountedPrice = founded.undiscounted_price
        item.currency = founded.price_currency
      }
      return item
    })
  } else {
    return []
  }
}
/**
 * Country Detection
 * @param {Array} i18n - @nuxt/i18n object
 */

export const browserLanguageDetect = (i18n) => {
  const browserLocale = window.navigator.language
  const redirectLocales = i18n.localeCodes.filter(
    (e) => e !== 'us' && e !== 'ie'
  )
  const returnLocaleCode = redirectLocales.find((code) => {
    return code === browserLocale
  })
  return returnLocaleCode || i18n.locale
}

export const getDate = (str, format = 'YYYY, DD MM') => {
  if (format === 'from') {
    dayjs(str).fromNow()
  }

  if (format === 'long') {
    return dayjs(str).format('MMMM Do, YYYY hh:mm a')
  }

  if (format === 'longUtc') {
    return dayjs(str).utc().format('D MMM YYYY [at] HH:mm')
  }
  return dayjs(str).format(format)
}

// callback - the function to run after onLoad
// delay - wait X milliseconds after onLoad
export const onLoad = (callback, delay = 1) => {
  // missed the load event, run now
  if (document.readyState === 'complete') {
    setTimeout(() => callback(), delay)
  } else {
    window.addEventListener('load', function () {
      setTimeout(() => callback(), delay)
    })
  }
}
