
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    multiline: {
      type: Boolean,
      default: false,
    },
    auth: {
      type: Boolean,
      default: false,
    },
    checkout: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: null,
    },
    success: {
      type: String,
      default: null,
    },
    slidingLabel: {
      type: String,
      default: null,
    },
    labelVisible: {
      type: Boolean,
      default: false,
    },
    textCenter: {
      type: Boolean,
      default: false,
    },
    applyValidation: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      required: false,
      default: null,
    },
    vid: {
      type: String,
      required: false,
      default: null,
    },
    ignoreErrorContent: {
      type: Boolean,
      default: false,
    },
    mask: {
      type: String,
      default: null,
    },
    isHideProp: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
    noSpace: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      isHide: this.isHideProp,
    }
  },
  computed: {
    inputListeners() {
      const self = this
      return {
        ...this.$listeners,
        input(event) {
          self.$emit('input', event.target.value)
        },
      }
    },
    theme() {
      if (this.auth || this.checkout) {
        return true
      }

      return false
    },
    validationRules() {
      if (this.applyValidation) {
        if (!this.required) return this.rules
        return this.rules ? `required|${this.rules}` : 'required'
      } else {
        return null
      }
    },
  },
  watch: {
    error(val) {
      // set backend error content
      this.$refs.validationObserver.setErrors([val])
      val !== null && (this.$refs.validationObserver.flags.invalid = true)
    },
  },
  mounted() {
    this.$refs?.validationObserver.reset()
  },
  methods: {
    debounce(value) {
      this.$emit('debounce', value)
    },
    passwordHideShow() {
      this.isHide = !this.isHide
    },
  },
}
